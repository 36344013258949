import React, { useState, useEffect } from "react";
import { Container } from "../../style";
import moment from "moment";
import {
  SouthKoreaFlagSvg,
  CalenderSvg,
  ClockSvg,
  TemperatureSvg,
} from "src/common/svg";
import { Body1, H6 } from "src/common/theme";

function KoreanDate() {
  const [weather, setWeather] = useState({} as any);

  const getWeather = async () => {
    const response = await fetch(
      "https://api.openweathermap.org/data/2.5/weather?q=seoul&appid=18e3a52f103534840fce989cf3381180&units=metric"
    );
    setWeather(await response.json());
  };

  useEffect(() => {
    getWeather();
  }, []);

  return (
    <div>
      <Container style={{ borderBottom: "1.5px solid #E5E5E5" }}>
        <SouthKoreaFlagSvg />
        <H6 color="black">Seoul, Korea</H6>
      </Container>
      <Container>
        <CalenderSvg />
        <Body1 style={{ fontWeight: 450 }}>
          {moment().format("D MMMM  YYYY, dddd")}
        </Body1>
      </Container>
      <Container>
        <Container>
          <ClockSvg />
          <Body1 style={{ fontWeight: 450 }}>
            {new Date().toLocaleString("en-US", {
              timeZone: "Asia/Seoul",
              timeStyle: "medium",
            })}
          </Body1>
        </Container>
        <Container>
          <TemperatureSvg />
          <Body1 style={{ fontWeight: 450 }}>{weather?.main?.temp} C</Body1>
        </Container>
      </Container>
    </div>
  );
}

export default KoreanDate;
