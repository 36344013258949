import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "src/model/user";
import { SingleResponseUtils } from "src/types/response/utils";

interface UserResource {
  profile: User | null;
  // users: User[];
}

const initialState: UserResource = {
  profile: null,
  // users: []
};

const studentProfileData = createSlice({
  name: "student",
  initialState,

  reducers: {
    getStudent: (state, action) => {
      state.profile = action.payload.data.enrollments;
    },

    getProfile: (state, action) => {
      state.profile = action.payload.data;
    },

    updateProfile: (
      state,
      action: PayloadAction<SingleResponseUtils<User>>
    ) => {
      return {
        ...state,
        profile: action.payload.data,
        // users: userList,
      };
    },
  },
});

export const { getStudent, getProfile, updateProfile } =
  studentProfileData.actions;
export default studentProfileData;
