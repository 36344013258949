import styled from "styled-components";
import BackgroundImage from "src/common/images/background-image.webp";

export const Main = styled.div`
  margin: 100px 0;
  width: 100%;
  height: 807px;
  padding: 100px;
  background-size: cover;
  background-image: url(${BackgroundImage});
  background-color: #f3f7fb;
  @media (max-width: 600px) {
    padding: 10px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 539px;
  height: 100%;
  // border: 1px solid red;
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
  }
  @media (max-width: 375px) {
    width: 100%;
  }
`;
export const ContactWrapper = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  place-items: center;
  background: #00306e;
  position: relative;
  justify-content: center;
  place-items: center;
  margin-top: 20px;
  background-size: cover;
  @media (max-width: 1200px) {
    height: 200px;
  }
  @media (max-width: 600px) {
    height: 100px;
  }
`;

export const PhysicalFormWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 25px;
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
  }
`;

export const LeftBarWrapper = styled.div`
  width: 194px;
  height: 215px;
  position: absolute;
  top: -20px;
  left: -100px;
  @media (max-width: 800px) {
    width: 150px;
    height: 171px;
    position: absolute;
    top: 0;
    left: 0;
  }
  @media (max-width: 600px) {
    width: 100px;
    height: 79px;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const RightBarWrapper = styled.div`
  width: 166px;
  height: 165px;
  position: absolute;
  top: 100px;
  right: 0;
  @media (max-width: 800px) {
    width: 122px;
    height: 143px;
    position: absolute;
    top: 0;
    right: 0;
  }
  @media (max-width: 600px) {
    width: 72px;
    height: 94px;
    position: absolute;
    top: 0;
    right: 0;
  }
`;
