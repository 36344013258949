import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Article } from "src/model/article";

interface articleResource {
  total: number;
  blogs: Article[];
}

const initialState: articleResource = {
  total: 0,
  blogs: [],
};

export const blogSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {
    getBlogs: (state, action: any) => {
      return {
        ...state,
        total: state.total,
        blogs: action.payload.data,
      };
    },

    // deletearticle: (state, action: any) => {
    //   const articles = [...state.articles];
    //   const updatedarticles = articles.filter(
    //     (article) => article.id !== action.payload
    //   );

    //   return {
    //     ...state,
    //     articles: updatedarticles,
    //   };
    // },
  },
});

export const { getBlogs } = blogSlice.actions;

export default blogSlice;
