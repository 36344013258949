import React from "react";
import { RootWrapper } from "src/common/wrapper/root-wrapper";
import AboutBackgroundImg from "src/common/images/about-bg-image.png";
import Welcome from "./welcome/index";
import Learn from "./learn/index";
import { H2 } from "src/common/theme";
import { HeaderFooter } from "src/App";
import { LeftBar, RightBar } from "src/common/svg";
import styled from "styled-components";

const Box = styled.div`
  margin-top: 20px;
  display: grid;
  place-items: center;
  height: 300px;
  position: relative;
  width: 100%;
  background: #00306e;
  color: #ffffff;
  @media (max-width: 1200px) {
    height: 200px;
  }
  @media (max-width: 600px) {
    height: 100px;
  }
`;

export const LeftBarWrapper = styled.div`
  width: 194px;
  height: 215px;
  position: absolute;
  top: 0;
  left: 0;
  @media (max-width: 800px) {
    width: 150px;
    height: 171px;
    position: absolute;
    top: 0px;
    left: 0px;
  }
  @media (max-width: 600px) {
    width: 100px;
    height: 79px;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const RightBarWrapper = styled.div`
  width: 166px;
  height: 165px;
  position: absolute;
  top: 100px;
  right: 0;
  @media (max-width: 800px) {
    width: 122px;
    height: 143px;
    position: absolute;
    top: 0;
    right: 0;
  }
  @media (max-width: 600px) {
    width: 72px;
    height: 94px;
    position: absolute;
    top: 0;
    right: 0;
  }
`;

function index() {
  return (
    <HeaderFooter>
      <Box>
        <LeftBarWrapper>
          <LeftBar />
        </LeftBarWrapper>
        <RightBarWrapper>
          <RightBar />
        </RightBarWrapper>
        <H2 color="white">ABOUT US</H2>
      </Box>

      <Welcome />
      <div
        style={{
          width: "100%",
          minHeight: "550",
          marginBottom: "110px",
          paddingTop: "10px",
          paddingBottom: "10px",
          background: "#F3F7FB",
        }}
      >
        <Learn />
      </div>
    </HeaderFooter>
  );
}

export default index;
