export function Profileimg() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35 66.5C52.3967 66.5 66.5 52.3967 66.5 35C66.5 17.6033 52.3967 3.5 35 3.5C17.6033 3.5 3.5 17.6033 3.5 35C3.5 52.3967 17.6033 66.5 35 66.5ZM35 70C54.3305 70 70 54.3305 70 35C70 15.6695 54.3305 0 35 0C15.6695 0 0 15.6695 0 35C0 54.3305 15.6695 70 35 70Z"
        fill="#1A1A1A"
      />
      <path
        d="M14 55.3531C14 53.5453 15.351 52.0176 17.15 51.8181C30.6513 50.3236 39.41 50.4583 52.8815 51.8513C53.5542 51.922 54.1918 52.187 54.7164 52.614C55.241 53.0411 55.6298 53.6116 55.8355 54.256C56.0411 54.9004 56.0546 55.5908 55.8744 56.2427C55.6941 56.8947 55.3279 57.4801 54.8205 57.9273C38.9218 71.7856 29.8358 71.5948 15.12 57.9413C14.4025 57.2763 14 56.3313 14 55.3548V55.3531Z"
        fill="#1A1A1A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.7013 53.5897C39.3365 52.2072 30.7038 52.0777 17.3408 53.5565C16.9009 53.6077 16.4954 53.8195 16.2019 54.1511C15.9083 54.4827 15.7475 54.9109 15.75 55.3537C15.75 55.8542 15.9583 56.3285 16.31 56.6575C23.604 63.423 29.1305 66.4802 34.5328 66.4995C39.9543 66.5187 45.7783 63.486 53.6708 56.6085C53.9215 56.3853 54.102 56.0941 54.1903 55.7703C54.2787 55.4464 54.271 55.1039 54.1683 54.7843C54.0656 54.4648 53.8723 54.1819 53.6119 53.9701C53.3514 53.7584 53.0351 53.6268 52.7013 53.5915V53.5897ZM16.9575 50.0775C30.6005 48.5672 39.487 48.7037 53.0635 50.109C54.0755 50.2146 55.0348 50.613 55.8239 51.2555C56.613 51.8979 57.1976 52.7565 57.5063 53.726C57.8149 54.6956 57.8342 55.7342 57.5617 56.7145C57.2892 57.6949 56.7367 58.5745 55.972 59.2457C47.9658 66.2247 41.2983 70.0257 34.5223 69.9995C27.727 69.975 21.3535 66.1092 13.9318 59.223C13.4004 58.728 12.9768 58.1288 12.6874 57.4628C12.398 56.7967 12.2491 56.0782 12.25 55.352C12.2475 54.0472 12.7264 52.7874 13.595 51.8138C14.4636 50.8403 15.6609 50.2214 16.9575 50.0757V50.0775Z"
        fill="#1A1A1A"
      />
      <path
        d="M49 28C49 31.713 47.525 35.274 44.8995 37.8995C42.274 40.525 38.713 42 35 42C31.287 42 27.726 40.525 25.1005 37.8995C22.475 35.274 21 31.713 21 28C21 24.287 22.475 20.726 25.1005 18.1005C27.726 15.475 31.287 14 35 14C38.713 14 42.274 15.475 44.8995 18.1005C47.525 20.726 49 24.287 49 28V28Z"
        fill="#1A1A1A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35 38.5C37.7848 38.5 40.4555 37.3938 42.4246 35.4246C44.3938 33.4555 45.5 30.7848 45.5 28C45.5 25.2152 44.3938 22.5445 42.4246 20.5754C40.4555 18.6062 37.7848 17.5 35 17.5C32.2152 17.5 29.5445 18.6062 27.5754 20.5754C25.6062 22.5445 24.5 25.2152 24.5 28C24.5 30.7848 25.6062 33.4555 27.5754 35.4246C29.5445 37.3938 32.2152 38.5 35 38.5V38.5ZM35 42C38.713 42 42.274 40.525 44.8995 37.8995C47.525 35.274 49 31.713 49 28C49 24.287 47.525 20.726 44.8995 18.1005C42.274 15.475 38.713 14 35 14C31.287 14 27.726 15.475 25.1005 18.1005C22.475 20.726 21 24.287 21 28C21 31.713 22.475 35.274 25.1005 37.8995C27.726 40.525 31.287 42 35 42V42Z"
        fill="#1A1A1A"
      />
    </svg>
  );
}

export function Person() {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 47.5C37.4263 47.5 47.5 37.4263 47.5 25C47.5 12.5737 37.4263 2.5 25 2.5C12.5737 2.5 2.5 12.5737 2.5 25C2.5 37.4263 12.5737 47.5 25 47.5ZM25 50C38.8075 50 50 38.8075 50 25C50 11.1925 38.8075 0 25 0C11.1925 0 0 11.1925 0 25C0 38.8075 11.1925 50 25 50Z"
        fill="#1A1A1A"
      />
      <path
        d="M10 39.5377C10 38.2465 10.965 37.1552 12.25 37.0127C21.8938 35.9452 28.15 36.0415 37.7725 37.0365C38.253 37.0869 38.7084 37.2762 39.0831 37.5812C39.4578 37.8863 39.7356 38.2938 39.8825 38.7541C40.0294 39.2144 40.039 39.7075 39.9103 40.1732C39.7815 40.6389 39.52 41.057 39.1575 41.3765C27.8013 51.2752 21.3113 51.139 10.8 41.3865C10.2875 40.9115 10 40.2365 10 39.539V39.5377Z"
        fill="#1A1A1A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.6438 38.2786C28.0975 37.2911 21.9313 37.1986 12.3863 38.2548C12.0721 38.2915 11.7824 38.4427 11.5728 38.6795C11.3631 38.9164 11.2482 39.2223 11.25 39.5386C11.25 39.8961 11.3988 40.2348 11.65 40.4698C16.86 45.3023 20.8075 47.4861 24.6663 47.4998C28.5388 47.5136 32.6988 45.3473 38.3363 40.4348C38.5154 40.2754 38.6443 40.0674 38.7074 39.8361C38.7705 39.6048 38.765 39.3601 38.6917 39.1319C38.6183 38.9036 38.4802 38.7016 38.2942 38.5503C38.1082 38.399 37.8822 38.3051 37.6438 38.2798V38.2786ZM12.1125 35.7698C21.8575 34.6911 28.205 34.7886 37.9025 35.7923C38.6254 35.8678 39.3106 36.1524 39.8742 36.6113C40.4378 37.0701 40.8554 37.6834 41.0759 38.3759C41.2964 39.0685 41.3101 39.8103 41.1155 40.5106C40.9208 41.2108 40.5262 41.8391 39.98 42.3186C34.2613 47.3036 29.4988 50.0186 24.6588 49.9998C19.805 49.9823 15.2525 47.2211 9.95126 42.3023C9.5717 41.9488 9.26912 41.5208 9.06244 41.0451C8.85575 40.5693 8.74939 40.0561 8.75001 39.5373C8.74818 38.6054 9.09026 37.7055 9.71071 37.0101C10.3312 36.3147 11.1864 35.8726 12.1125 35.7686V35.7698Z"
        fill="#1A1A1A"
      />
      <path
        d="M35 20C35 22.6522 33.9464 25.1957 32.0711 27.0711C30.1957 28.9464 27.6522 30 25 30C22.3478 30 19.8043 28.9464 17.9289 27.0711C16.0536 25.1957 15 22.6522 15 20C15 17.3478 16.0536 14.8043 17.9289 12.9289C19.8043 11.0536 22.3478 10 25 10C27.6522 10 30.1957 11.0536 32.0711 12.9289C33.9464 14.8043 35 17.3478 35 20Z"
        fill="#1A1A1A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 27.5C26.9891 27.5 28.8968 26.7098 30.3033 25.3033C31.7098 23.8968 32.5 21.9891 32.5 20C32.5 18.0109 31.7098 16.1032 30.3033 14.6967C28.8968 13.2902 26.9891 12.5 25 12.5C23.0109 12.5 21.1032 13.2902 19.6967 14.6967C18.2902 16.1032 17.5 18.0109 17.5 20C17.5 21.9891 18.2902 23.8968 19.6967 25.3033C21.1032 26.7098 23.0109 27.5 25 27.5ZM25 30C27.6522 30 30.1957 28.9464 32.0711 27.0711C33.9464 25.1957 35 22.6522 35 20C35 17.3478 33.9464 14.8043 32.0711 12.9289C30.1957 11.0536 27.6522 10 25 10C22.3478 10 19.8043 11.0536 17.9289 12.9289C16.0536 14.8043 15 17.3478 15 20C15 22.6522 16.0536 25.1957 17.9289 27.0711C19.8043 28.9464 22.3478 30 25 30Z"
        fill="#1A1A1A"
      />
    </svg>
  );
}

export function FacebookSvg() {
  return (
    <svg
      width="9"
      height="15"
      viewBox="0 0 9 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.62891 8.125L8.01172 5.60938H5.57812V3.96875C5.57812 3.25781 5.90625 2.60156 7 2.60156H8.12109V0.441406C8.12109 0.441406 7.10938 0.25 6.15234 0.25C4.15625 0.25 2.84375 1.48047 2.84375 3.66797V5.60938H0.601562V8.125H2.84375V14.25H5.57812V8.125H7.62891Z"
        fill="white"
      />
    </svg>
  );
}

export function TwitterSvg() {
  return (
    <svg
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.821 3.40625C12.821 3.54297 12.821 3.65234 12.821 3.78906C12.821 7.58984 9.94995 11.9375 4.67261 11.9375C3.03198 11.9375 1.52808 11.4727 0.270264 10.6523C0.489014 10.6797 0.707764 10.707 0.953857 10.707C2.2937 10.707 3.52417 10.2422 4.50854 9.47656C3.25073 9.44922 2.18433 8.62891 1.82886 7.48047C2.02026 7.50781 2.18433 7.53516 2.37573 7.53516C2.62183 7.53516 2.89526 7.48047 3.11401 7.42578C1.80151 7.15234 0.817139 6.00391 0.817139 4.60938V4.58203C1.19995 4.80078 1.66479 4.91016 2.12964 4.9375C1.33667 4.41797 0.844482 3.54297 0.844482 2.55859C0.844482 2.01172 0.981201 1.51953 1.22729 1.10938C2.64917 2.83203 4.78198 3.98047 7.16089 4.11719C7.1062 3.89844 7.07886 3.67969 7.07886 3.46094C7.07886 1.875 8.36401 0.589844 9.94995 0.589844C10.7703 0.589844 11.5085 0.917969 12.0554 1.49219C12.6843 1.35547 13.3132 1.10938 13.8601 0.78125C13.6414 1.46484 13.2039 2.01172 12.6023 2.36719C13.1765 2.3125 13.7507 2.14844 14.2429 1.92969C13.8601 2.50391 13.3679 2.99609 12.821 3.40625Z"
        fill="white"
      />
    </svg>
  );
}
export function LinkedInSvg() {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.86951 13.5H0.326538V5.32422H2.86951V13.5ZM1.58435 4.23047C0.791382 4.23047 0.135132 3.54688 0.135132 2.72656C0.135132 1.60547 1.33826 0.894531 2.32263 1.46875C2.78748 1.71484 3.06091 2.20703 3.06091 2.72656C3.06091 3.54688 2.40466 4.23047 1.58435 4.23047ZM12.3578 13.5H9.84216V9.53516C9.84216 8.57812 9.81482 7.375 8.50232 7.375C7.18982 7.375 6.99841 8.38672 6.99841 9.45312V13.5H4.45544V5.32422H6.88904V6.44531H6.91638C7.27185 5.81641 8.09216 5.13281 9.32263 5.13281C11.8929 5.13281 12.3851 6.82812 12.3851 9.01562V13.5H12.3578Z"
        fill="white"
      />
    </svg>
  );
}

export function YoutubeSvg() {
  return (
    <svg
      width="16"
      height="11"
      viewBox="0 0 16 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0928 1.66797C14.9287 1.01172 14.4092 0.492188 13.7803 0.328125C12.6045 0 7.95605 0 7.95605 0C7.95605 0 3.28027 0 2.10449 0.328125C1.47559 0.492188 0.956055 1.01172 0.791992 1.66797C0.463867 2.81641 0.463867 5.27734 0.463867 5.27734C0.463867 5.27734 0.463867 7.71094 0.791992 8.88672C0.956055 9.54297 1.47559 10.0352 2.10449 10.1992C3.28027 10.5 7.95605 10.5 7.95605 10.5C7.95605 10.5 12.6045 10.5 13.7803 10.1992C14.4092 10.0352 14.9287 9.54297 15.0928 8.88672C15.4209 7.71094 15.4209 5.27734 15.4209 5.27734C15.4209 5.27734 15.4209 2.81641 15.0928 1.66797ZM6.4248 7.49219V3.0625L10.3076 5.27734L6.4248 7.49219Z"
        fill="white"
      />
    </svg>
  );
}

export function LogoSvg() {
  return (
    <svg
      width="76"
      height="81"
      viewBox="0 0 56 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.8505 28.1372V50.2968L48.6728 49.8503C46.1253 49.6925 43.2733 50.2635 40.5608 51.2727C37.5484 52.3939 34.7406 54.0405 32.7231 55.8221L28 59.9999L23.2769 55.8221C21.2617 54.0385 18.4538 52.3919 15.4391 51.2727C12.7266 50.2635 9.8746 49.6925 7.3271 49.8503L0.149475 50.2968V28.1372H2.97822V47.5933L7.13883 47.3337C10.1396 47.1468 13.4332 47.7947 16.52 48.9429C19.8601 50.1846 22.9933 52.0285 25.2619 54.0364L27.9976 56.4554L30.7381 54.0385C33.0066 52.0306 36.1399 50.1888 39.48 48.945C42.5667 47.7968 45.8604 47.1489 48.8611 47.3358L53.0217 47.5953V28.1393H55.8505V28.1372Z"
        fill="#0D5EF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.415 48.8353V52.1077C31.3233 50.5608 39.426 44.5288 49.0419 45.1268L50.5504 45.2203V18.2226L49.2302 18.1396C40.5719 17.6018 33.0828 21.8232 29.4173 24.3626V27.6351V48.8353H29.415ZM46.8454 39.4852C43.5238 39.6035 40.4278 40.3905 37.7269 41.4225C35.8511 42.1388 34.1683 42.9756 32.7342 43.7917V42.3091C34.0498 41.5969 35.542 40.8868 37.1737 40.2618C40.028 39.1696 43.3147 38.339 46.8454 38.2206V39.4852ZM46.8454 32.4856C43.5238 32.6039 40.4278 33.3909 37.7269 34.4229C35.8511 35.1392 34.1683 35.976 32.7342 36.7921V35.3095C34.0498 34.5973 35.542 33.8872 37.1737 33.2622C40.028 32.17 43.3147 31.3394 46.8454 31.221V32.4856ZM46.8454 25.486C43.5238 25.6043 40.4278 26.3913 37.7269 27.4233C35.8511 28.1396 34.1683 28.9764 32.7342 29.7925V28.3099C34.0498 27.5977 35.542 26.8875 37.1737 26.2625C40.028 25.1703 43.3147 24.3398 46.8454 24.2214V25.486ZM23.0764 43.6837C21.6841 42.903 20.0687 42.1077 18.2766 41.4225C15.5246 40.3697 12.3588 39.5724 8.96523 39.479V38.2165C12.5703 38.3099 15.922 39.1509 18.8275 40.2618C20.3802 40.8556 21.8073 41.5284 23.0764 42.2053V43.6837ZM23.0764 36.6841C21.6841 35.9034 20.0687 35.1081 18.2766 34.4229C15.5246 33.3701 12.3588 32.5728 8.96523 32.4793V31.2169C12.5703 31.3103 15.922 32.1513 18.8275 33.2622C20.3802 33.856 21.8073 34.5288 23.0764 35.2057V36.6841ZM23.0764 29.6845C21.6841 28.9037 20.0687 28.1085 18.2766 27.4233C15.5246 26.3705 12.3588 25.5732 8.96523 25.4797V24.2173C12.5703 24.3107 15.922 25.1517 18.8275 26.2625C20.3802 26.8564 21.8073 27.5292 23.0764 28.2061V29.6845ZM26.5885 27.6371V24.3647C22.9207 21.8252 15.4339 17.6018 6.77568 18.1417L5.45544 18.2247V45.2223L6.96396 45.1289C16.5775 44.5309 24.6802 50.5629 26.5909 52.1098V48.8374V27.6371H26.5885Z"
        fill="#0D5EF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.6519 12.9537V15.9469C39.3913 16.52 35.2958 17.9548 31.5559 19.917C30.4007 20.5213 29.1804 21.2293 27.9996 21.9955C26.8189 21.2293 25.5986 20.5233 24.4457 19.917C20.7081 17.9548 16.6126 16.52 12.352 15.9469V12.9328C12.5589 12.8194 12.8488 12.6652 13.2131 12.4831C14.0139 12.0826 15.1733 11.5475 16.6011 11.012C19.4604 9.93979 23.3773 8.87369 27.6381 8.87369C31.9009 8.87369 36.0009 9.94063 39.0459 11.0154C40.5661 11.5519 41.8175 12.0882 42.6876 12.4898C43.0982 12.6793 43.4236 12.8387 43.6519 12.9537ZM44.02 12.0196L53.4049 8.45311L28.0019 0L2.599 8.45311L11.9567 12.0093C12.165 11.8964 12.4366 11.7533 12.7659 11.5886C13.5927 11.1752 14.7843 10.6253 16.25 10.0757C19.1778 8.97776 23.2182 7.87369 27.6381 7.87369C32.056 7.87369 36.275 8.97693 39.3788 10.0724C40.933 10.6209 42.2135 11.1697 43.1066 11.5819C43.4821 11.7552 43.7894 11.9044 44.02 12.0196Z"
        fill="#F20F10"
      />
    </svg>
  );
}

export function NepalFlagSvg() {
  return (
    <svg
      width="19"
      height="24"
      viewBox="0 0 19 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_371_498)">
        <path
          d="M18.7152 12.8986L0.927536 0.318848V23.6812H18.7152L8.04226 12.8986H18.7152Z"
          fill="#22408B"
        />
        <path
          d="M16.2029 11.9999L1.82608 2.29199V22.7825H16.2029L5.93605 11.9999H16.2029Z"
          fill="#DD2F45"
        />
        <path
          d="M6.58032 17.4282L7.10687 16.695L6.20832 16.7831L6.29818 15.8845L5.56406 16.4111L5.19206 15.588L4.82006 16.4111L4.08594 15.8845L4.1749 16.7831L3.27635 16.695L3.8029 17.4282L2.97983 17.8002L3.8029 18.1722L3.27635 18.9054L4.1749 18.8174L4.08594 19.7159L4.82006 19.1894L5.19206 20.0124L5.56406 19.1894L6.29818 19.7159L6.20832 18.8174L7.10687 18.9054L6.58032 18.1722L7.40339 17.8002L6.58032 17.4282ZM5.19116 11.0063L5.19206 11.0081L5.19296 11.0063L5.20913 11.0081C6.5282 11.0081 7.59928 9.99271 7.7107 8.70149C7.39672 9.28577 6.89039 9.74353 6.27751 9.9972L6.12026 9.77795L6.66928 9.52995L6.12026 9.28195L6.4716 8.79224L5.87136 8.85155L5.93157 8.25131L5.44096 8.60265L5.19296 8.05273L4.94496 8.60265L4.45435 8.25131L4.51455 8.85155L3.91432 8.79224L4.26565 9.28195L3.71664 9.52995L4.26565 9.77795L4.1156 9.98731C3.51411 9.73098 3.01784 9.27749 2.70847 8.70149C2.76003 9.32619 3.04333 9.90902 3.50271 10.3355C3.9621 10.762 4.56436 11.0012 5.19116 11.0063Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_371_498">
          <rect width="18.7826" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function SouthKoreaFlagSvg() {
  return (
    <svg
      width="24"
      height="17"
      viewBox="0 0 24 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_643_523)">
        <path
          d="M23.859 14.4055C23.859 15.0936 23.5857 15.7536 23.0991 16.2402C22.6125 16.7268 21.9526 17.0001 21.2644 17.0001H3.10229C2.41416 17.0001 1.75421 16.7268 1.26763 16.2402C0.781049 15.7536 0.50769 15.0936 0.50769 14.4055V2.72985C0.50769 2.04172 0.781049 1.38177 1.26763 0.895193C1.75421 0.408612 2.41416 0.135254 3.10229 0.135254H21.2644C21.9526 0.135254 22.6125 0.408612 23.0991 0.895193C23.5857 1.38177 23.859 2.04172 23.859 2.72985V14.4055Z"
          fill="#EEEEEE"
        />
        <path
          d="M14.4154 5.37941C13.5699 4.78748 12.5238 4.55567 11.5073 4.73497C10.4909 4.91428 9.58728 5.49001 8.9953 6.33552C8.71576 6.75869 8.61242 7.27424 8.70726 7.77246C8.8021 8.27067 9.08764 8.7122 9.50311 9.00307C9.91857 9.29393 10.4311 9.41117 10.9317 9.32982C11.4323 9.24847 11.8814 8.97497 12.1834 8.56752C12.33 8.35818 12.5164 8.17977 12.7319 8.04247C12.9475 7.90517 13.188 7.81167 13.4397 7.76731C13.6913 7.72295 13.9493 7.7286 14.1988 7.78393C14.4483 7.83926 14.6844 7.94319 14.8938 8.08979C15.1031 8.23638 15.2815 8.42278 15.4188 8.63832C15.5561 8.85387 15.6496 9.09435 15.694 9.34603C15.7383 9.59772 15.7327 9.85567 15.6774 10.1052C15.622 10.3547 15.5181 10.5908 15.3715 10.8002C15.6647 10.3815 15.8726 9.90912 15.9832 9.41009C16.0938 8.91106 16.1051 8.39512 16.0163 7.89175C15.9275 7.38837 15.7404 6.90741 15.4657 6.47634C15.1911 6.04527 14.8341 5.67253 14.4154 5.37941Z"
          fill="#C60C30"
        />
        <path
          d="M14.8934 8.0902C14.4707 7.79417 13.9478 7.67816 13.4395 7.76769C12.9313 7.85722 12.4795 8.14496 12.1834 8.56761C11.8821 8.9772 11.4326 9.25267 10.9309 9.33511C10.4292 9.41754 9.9151 9.30041 9.49863 9.00875C9.08216 8.71709 8.79633 8.27406 8.70226 7.7744C8.6082 7.27473 8.71337 6.75809 8.99528 6.33496C8.69353 6.7532 8.47792 7.22723 8.36097 7.72952C8.24402 8.23181 8.22807 8.75234 8.31404 9.26085C8.40001 9.76936 8.58619 10.2557 8.86176 10.6916C9.13733 11.1276 9.49679 11.5044 9.91925 11.8002C10.3417 12.096 10.8188 12.3049 11.3227 12.4147C11.8265 12.5245 12.3472 12.5331 12.8545 12.4399C13.3617 12.3468 13.8454 12.1537 14.2774 11.872C14.7094 11.5903 15.0811 11.2256 15.3708 10.799C15.5175 10.5898 15.6214 10.3538 15.6768 10.1044C15.7322 9.85504 15.7378 9.59721 15.6935 9.34564C15.6492 9.09408 15.5557 8.85372 15.4184 8.6383C15.2811 8.42289 15.1027 8.23664 14.8934 8.0902Z"
          fill="#003478"
        />
        <path
          d="M16.2919 13.4793L17.5425 11.9887L18.0394 12.4058L16.7888 13.8964L16.2919 13.4793ZM17.959 11.4925L19.2096 10.0026L19.7064 10.4197L18.4558 11.9096L17.959 11.4925ZM17.2857 14.3148L18.5363 12.8248L19.0331 13.2419L17.7825 14.7319L17.2857 14.3148ZM18.954 12.326L20.2059 10.8361L20.7027 11.2538L19.4509 12.7431L18.954 12.326ZM18.2787 15.1489L19.5306 13.659L20.0269 14.0761L18.7756 15.566L18.2787 15.1489ZM19.9464 13.1608L21.1964 11.669L21.6939 12.086L20.4439 13.5779L19.9464 13.1608ZM19.949 3.97468L20.4459 3.5576L21.6978 5.04885L21.2003 5.46593L19.949 3.97468ZM18.2807 1.98593L18.7776 1.56885L20.0281 3.06009L19.5306 3.47652L18.2807 1.98593ZM17.2863 2.82139L17.7832 2.40431L20.7021 5.88236L20.2052 6.29944L17.2863 2.82139ZM16.2926 3.65425L16.7901 3.23717L18.04 4.72776L17.5432 5.1442L16.2926 3.65425ZM17.9596 5.64236L18.4565 5.22528L19.7084 6.71522L19.2121 7.1323L17.9596 5.64236ZM4.65907 10.419L5.15593 10.0019L8.0742 13.4806L7.57669 13.8977L4.65907 10.419ZM3.66664 11.2532L4.16285 10.8367L5.41409 12.3273L4.91658 12.7444L3.66664 11.2532ZM5.33172 13.2419L5.82858 12.8248L7.08047 14.3148L6.58361 14.7319L5.33172 13.2419ZM2.67161 12.0867L3.16847 11.6696L6.08739 15.1477L5.58988 15.5647L2.67161 12.0867ZM2.67096 5.04755L5.58858 1.5695L6.0861 1.98593L3.16718 5.46463L2.67096 5.04755ZM3.66469 5.88236L6.58361 2.40366L7.08047 2.82074L4.16155 6.29879L3.66469 5.88236ZM4.65907 6.71587L7.57799 3.23717L8.07485 3.65425L5.15593 7.13295L4.65907 6.71587Z"
          fill="#292F33"
        />
      </g>
      <defs>
        <clipPath id="clip0_643_523">
          <rect
            width="24"
            height="16.8649"
            fill="white"
            transform="translate(0 0.135254)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function CalenderSvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z"
        stroke="#F20F10"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 2V6"
        stroke="#F20F10"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 2V6"
        stroke="#F20F10"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 10H21"
        stroke="#F20F10"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ClockSvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke="#F20F10"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 6V12L16 14"
        stroke="#F20F10"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function TemperatureSvg() {
  return (
    <svg
      width="12"
      height="23"
      viewBox="0 0 12 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.41668 14.1452V3.35433C8.41668 2.71891 8.16426 2.10953 7.71495 1.66022C7.26565 1.21091 6.65626 0.958496 6.02084 0.958496C5.38543 0.958496 4.77604 1.21091 4.32673 1.66022C3.87743 2.10953 3.62501 2.71891 3.62501 3.35433V14.1452C2.85573 14.6592 2.27215 15.4069 1.96045 16.278C1.64876 17.1491 1.62547 18.0974 1.89403 18.9827C2.16259 19.8681 2.70876 20.6436 3.45187 21.1947C4.19499 21.7459 5.09565 22.0434 6.02084 22.0434C6.94603 22.0434 7.8467 21.7459 8.58981 21.1947C9.33293 20.6436 9.8791 19.8681 10.1477 18.9827C10.4162 18.0974 10.3929 17.1491 10.0812 16.278C9.76953 15.4069 9.18595 14.6592 8.41668 14.1452Z"
        stroke="#F20F10"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function FacebookIconSvg() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="19.5" fill="#193F85" stroke="#2A4F93" />
      <path
        d="M23.6289 20.625L24.0117 18.1094H21.5781V16.4688C21.5781 15.7578 21.9062 15.1016 23 15.1016H24.1211V12.9414C24.1211 12.9414 23.1094 12.75 22.1523 12.75C20.1562 12.75 18.8438 13.9805 18.8438 16.168V18.1094H16.6016V20.625H18.8438V26.75H21.5781V20.625H23.6289Z"
        fill="white"
      />
    </svg>
  );
}

export function TwitterIconSvg() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="19.5" fill="#193F85" stroke="#2A4F93" />
      <path
        d="M25.5508 16.9062C25.5508 17.043 25.5508 17.1523 25.5508 17.2891C25.5508 21.0898 22.6797 25.4375 17.4023 25.4375C15.7617 25.4375 14.2578 24.9727 13 24.1523C13.2188 24.1797 13.4375 24.207 13.6836 24.207C15.0234 24.207 16.2539 23.7422 17.2383 22.9766C15.9805 22.9492 14.9141 22.1289 14.5586 20.9805C14.75 21.0078 14.9141 21.0352 15.1055 21.0352C15.3516 21.0352 15.625 20.9805 15.8438 20.9258C14.5312 20.6523 13.5469 19.5039 13.5469 18.1094V18.082C13.9297 18.3008 14.3945 18.4102 14.8594 18.4375C14.0664 17.918 13.5742 17.043 13.5742 16.0586C13.5742 15.5117 13.7109 15.0195 13.957 14.6094C15.3789 16.332 17.5117 17.4805 19.8906 17.6172C19.8359 17.3984 19.8086 17.1797 19.8086 16.9609C19.8086 15.375 21.0938 14.0898 22.6797 14.0898C23.5 14.0898 24.2383 14.418 24.7852 14.9922C25.4141 14.8555 26.043 14.6094 26.5898 14.2812C26.3711 14.9648 25.9336 15.5117 25.332 15.8672C25.9062 15.8125 26.4805 15.6484 26.9727 15.4297C26.5898 16.0039 26.0977 16.4961 25.5508 16.9062Z"
        fill="white"
      />
    </svg>
  );
}

export function LinkedInIconSvg() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="19.5" fill="#193F85" stroke="#2A4F93" />
      <path
        d="M16.7344 25H14.1914V16.8242H16.7344V25ZM15.4492 15.7305C14.6562 15.7305 14 15.0469 14 14.2266C14 13.1055 15.2031 12.3945 16.1875 12.9688C16.6523 13.2148 16.9258 13.707 16.9258 14.2266C16.9258 15.0469 16.2695 15.7305 15.4492 15.7305ZM26.2227 25H23.707V21.0352C23.707 20.0781 23.6797 18.875 22.3672 18.875C21.0547 18.875 20.8633 19.8867 20.8633 20.9531V25H18.3203V16.8242H20.7539V17.9453H20.7812C21.1367 17.3164 21.957 16.6328 23.1875 16.6328C25.7578 16.6328 26.25 18.3281 26.25 20.5156V25H26.2227Z"
        fill="white"
      />
    </svg>
  );
}

export function PinterestSvg() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="19.5" fill="#193F85" stroke="#2A4F93" />
      <path
        d="M20.5781 12.9414C17.7617 12.9414 15 14.8008 15 17.8359C15 19.75 16.0664 20.8438 16.7227 20.8438C16.9961 20.8438 17.1602 20.1055 17.1602 19.8867C17.1602 19.6406 16.5039 19.0938 16.5039 18.0273C16.5039 15.8398 18.1719 14.2812 20.332 14.2812C22.2188 14.2812 23.5859 15.3477 23.5859 17.2891C23.5859 18.7383 23.0117 21.4453 21.125 21.4453C20.4414 21.4453 19.8398 20.9531 19.8398 20.2695C19.8398 19.2305 20.5781 18.2188 20.5781 17.1523C20.5781 15.3477 18.0078 15.6758 18.0078 17.8633C18.0078 18.3281 18.0625 18.8203 18.2812 19.2578C17.8984 20.8711 17.1328 23.3047 17.1328 24.9727C17.1328 25.4922 17.1875 25.9844 17.2422 26.5039C17.3242 26.6133 17.2969 26.6133 17.4336 26.5586C18.8008 24.6719 18.7734 24.2891 19.375 21.8281C19.7305 22.457 20.5781 22.8125 21.2891 22.8125C24.1875 22.8125 25.5 19.9688 25.5 17.4258C25.5 14.7188 23.1484 12.9414 20.5781 12.9414Z"
        fill="white"
      />
    </svg>
  );
}

export function BookSvg() {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 0H12C11.2596 0.00250783 10.5466 0.280565 10 0.78C9.45338 0.280565 8.74042 0.00250783 8 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V16C0 16.2652 0.105357 16.5196 0.292893 16.7071C0.48043 16.8946 0.734784 17 1 17H6.758C7.28817 17.0012 7.7964 17.2118 8.172 17.586L9.293 18.707C9.302 18.716 9.314 18.719 9.323 18.728C9.409 18.808 9.505 18.878 9.617 18.924H9.619C9.86296 19.025 10.137 19.025 10.381 18.924H10.383C10.495 18.878 10.591 18.807 10.677 18.728C10.686 18.719 10.698 18.716 10.707 18.707L11.828 17.586C12.2036 17.2118 12.7118 17.0012 13.242 17H19C19.2652 17 19.5196 16.8946 19.7071 16.7071C19.8946 16.5196 20 16.2652 20 16V1C20 0.734784 19.8946 0.48043 19.7071 0.292893C19.5196 0.105357 19.2652 0 19 0ZM18 15H13.242C12.4429 15.0023 11.6625 15.2421 11 15.689V3C11 2.449 11.448 2 12 2H18V15Z"
        fill="#0D5EF4"
      />
    </svg>
  );
}

export function YouTubeIconSvg() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="19.5" fill="#193F85" stroke="#2A4F93" />
      <path
        d="M27.6661 16.8745C27.575 16.5115 27.3964 16.1805 27.1482 15.9146C26.9 15.6487 26.5909 15.4572 26.2518 15.3592C25.0036 15 20 15 20 15C20 15 14.9964 15 13.7482 15.3573C13.409 15.4549 13.0997 15.6463 12.8514 15.9123C12.6032 16.1783 12.4247 16.5095 12.3339 16.8726C12 18.2102 12 21 12 21C12 21 12 23.7898 12.3339 25.1255C12.5179 25.8631 13.0607 26.4439 13.7482 26.6408C14.9964 27 20 27 20 27C20 27 25.0036 27 26.2518 26.6408C26.9411 26.4439 27.4821 25.8631 27.6661 25.1255C28 23.7898 28 21 28 21C28 21 28 18.2102 27.6661 16.8745ZM18.4107 23.5605V18.4395L22.5536 20.9809L18.4107 23.5605Z"
        fill="#FFFEFE"
      />
    </svg>
  );
}
export function MusicSvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 11.9998V10.2928C20 5.85082 16.521 2.13182 12.245 2.00282C10.041 1.95182 7.994 2.73882 6.429 4.25882C5.65744 5.00214 5.04444 5.89405 4.62697 6.88074C4.2095 7.86743 3.99623 8.92846 4 9.99982V11.9998C2.897 11.9998 2 12.8968 2 13.9998V17.9998C2 19.1028 2.897 19.9998 4 19.9998H6V9.99982C5.99705 9.19631 6.15688 8.40053 6.46985 7.66047C6.78282 6.92041 7.24245 6.25141 7.821 5.69382C8.39712 5.133 9.08047 4.69417 9.83014 4.40362C10.5798 4.11306 11.3804 3.97674 12.184 4.00282C15.392 4.09882 18 6.92082 18 10.2928V19.9998H20C21.103 19.9998 22 19.1028 22 17.9998V13.9998C22 12.8968 21.103 11.9998 20 11.9998Z"
        fill="#0D5EF4"
      />
      <path d="M7 12H9V20H7V12ZM15 12H17V20H15V12Z" fill="#0D5EF4" />
    </svg>
  );
}

export function LeftBar() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 194 215"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M95.5956 -11.7464L94.4834 -10.5899L-24.1602 114.347L-19.5424 131.87L105.235 0.484458L106.348 -0.707075L114.336 -9.11796L97.4495 -13.6738L95.5956 -11.7464Z"
        fill="white"
        fillOpacity="0.5"
      />
      width: 166px; height: 165px;
      <path
        d="M122.492 15.8701L121.38 17.0265L-12.5999 158.12L-7.98221 175.607L132.132 28.0659L133.244 26.9094L156.568 2.37757L139.648 -2.21338L122.492 15.8701Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M148.277 44.642L-1.07227 201.856L2.23091 214.262L98.022 113.366L193.847 12.5054L181.882 9.24609L149.356 43.4856L148.277 44.642Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M29.9044 -32.0365L13.0179 -36.6274L-47.2812 26.8749L-42.6636 44.3627L29.9044 -32.0365Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M72.137 -20.578L61.9243 -23.3466L55.2169 -25.1689L-35.7207 70.6102L-33.9006 77.5493L-31.1031 88.0979L72.137 -20.578Z"
        fill="white"
        fillOpacity="0.5"
      />
    </svg>
  );
}

export function RightBar() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 166 165"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M143 29.2688L142.039 29.9589L39.584 104.512L43.5717 114.968L151.325 36.5673L152.285 35.8562L159.183 30.8373L144.601 28.1187L143 29.2688Z"
        fill="url(#paint0_linear_78_1536)"
        fillOpacity="0.5"
      />
      <path
        d="M166.228 45.7479L165.267 46.438L49.5684 130.632L53.556 141.067L174.552 53.0255L175.513 52.3353L195.655 37.6966L181.043 34.957L166.228 45.7479Z"
        fill="url(#paint1_linear_78_1536)"
        fillOpacity="0.5"
      />
      <path
        d="M188.495 62.9166L59.5234 156.73L62.3759 164.133L145.097 103.926L227.847 43.7398L217.514 41.7949L189.426 62.2265L188.495 62.9166Z"
        fill="url(#paint2_linear_78_1536)"
        fillOpacity="0.5"
      />
      <path
        d="M86.2714 17.1609L71.689 14.4214L19.6172 52.3148L23.6048 62.7502L86.2714 17.1609Z"
        fill="url(#paint3_linear_78_1536)"
        fillOpacity="0.5"
      />
      <path
        d="M13.3599 3.48365L0 0.974121L3.63833 10.5311L13.3599 3.48365Z"
        fill="url(#paint4_linear_78_1536)"
        fillOpacity="0.5"
      />
      <path
        d="M49.8311 10.3221L35.2195 7.58252L9.63477 26.1946L13.6224 36.6509L49.8311 10.3221Z"
        fill="url(#paint5_linear_78_1536)"
        fillOpacity="0.5"
      />
      <path
        d="M122.743 23.9983L113.924 22.3462L108.131 21.2588L29.6016 78.4126L31.1733 82.5533L33.5892 88.8479L122.743 23.9983Z"
        fill="url(#paint6_linear_78_1536)"
        fillOpacity="0.5"
      />
      <defs>
        <linearGradient
          id="paint0_linear_78_1536"
          x1="200.732"
          y1="78.5783"
          x2="70.9748"
          y2="-49.7958"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.760825" stopColor="white" stopOpacity="0.35" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_78_1536"
          x1="246.404"
          y1="96.607"
          x2="87.8722"
          y2="-60.1969"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.760825" stopColor="white" stopOpacity="0.35" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_78_1536"
          x1="286.322"
          y1="112.873"
          x2="103.546"
          y2="-67.7982"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.760825" stopColor="white" stopOpacity="0.35" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_78_1536"
          x1="109.427"
          y1="42.5005"
          x2="37.2205"
          y2="-29.0451"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.760825" stopColor="white" stopOpacity="0.35" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_78_1536"
          x1="18.0011"
          y1="6.52676"
          x2="3.72186"
          y2="-7.81407"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.760825" stopColor="white" stopOpacity="0.35" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_78_1536"
          x1="63.7951"
          y1="24.4713"
          x2="20.3642"
          y2="-18.6757"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.760825" stopColor="white" stopOpacity="0.35" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_78_1536"
          x1="155.1"
          y1="60.5281"
          x2="54.1181"
          y2="-39.4475"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.760825" stopColor="white" stopOpacity="0.35" />
        </linearGradient>
      </defs>
    </svg>
  );
}
