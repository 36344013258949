import React from "react";
import { Main, CompanyInfoWrapper } from "../style";
import { Body1, Subtitle1, UnderLine } from "src/common/theme";
import { useNavigate } from "react-router-dom";

function CompanyInfo() {
  const navigate = useNavigate();
  return (
    <>
      <CompanyInfoWrapper>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            cursor: "pointer",
          }}
        >
          <div>
            <Subtitle1 color="white">COMPANY INFO</Subtitle1>
            <UnderLine style={{ background: "white" }} />
            <Main>
              <Body1 color="white" onClick={() => navigate("/about")}>
                About Us
              </Body1>
              <Body1 color="white" onClick={() => navigate("/contact")}>
                Contact Us
              </Body1>
            </Main>
          </div>
          <div>
            <Subtitle1 color="white">CATAGORIES</Subtitle1>
            <UnderLine style={{ background: "white" }} />
            <Main>
              <Body1 color="white" onClick={() => navigate("/")}>
                Home
              </Body1>
              <Body1 color="white" onClick={() => navigate("/classes")}>
                Classes
              </Body1>
              <Body1 color="white" onClick={() => navigate("/exam")}>
                Exam
              </Body1>
            </Main>
          </div>
          <div>
            <Subtitle1 color="white">OUR COURSES</Subtitle1>
            <UnderLine style={{ background: "white" }} />
            <Main>
              <Body1 color="white" onClick={() => navigate("/exam")}>
                UBT
              </Body1>
              <Body1 color="white" onClick={() => navigate("/exam")}>
                CBT
              </Body1>
            </Main>
          </div>
        </div>
      </CompanyInfoWrapper>
    </>
  );
}

export default CompanyInfo;
