import { BookSvg, MusicSvg } from 'src/common/svg'
import { H5 } from 'src/common/theme'
import { useAppSelector } from 'src/hooks/hooks'
import { QuestionEnum } from 'src/model/modelSet'
import QuestionNumber from '../total-question/question-number/index'
import { QuestionWrapper } from '../total-question/style'

interface UnsolvedQuestionProps {
  activeHandler: (step: number) => void
}

export default function UnsolvedQuestion({ activeHandler }: UnsolvedQuestionProps) {
  const result = useAppSelector((state) => state.result.result)

  return (
    <div
      style={{
        position: 'relative',
        top: '20px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          padding: '0 20px',
          textAlign: 'center',
        }}
      >
        <div
          style={{
            maxWidth: '50%',
            flex: '0 0 50%',
            padding: '0 15px',
          }}
        >
          <QuestionWrapper>
            <BookSvg />
            <H5>
              읽기 (
              {
                result!.questionAns.filter(
                  (each) =>
                    each.selectedAnswer === '' &&
                    ((each.type === QuestionEnum.text && each.option_type !== QuestionEnum.audio) || (each.type === QuestionEnum.image && each.option_type !== QuestionEnum.audio))
                ).length
              }{' '}
              Questions)
            </H5>
          </QuestionWrapper>
          <QuestionNumber
            activeHandler={activeHandler}
            allQuestionAns={result!.questionAns.filter(
              (each) =>
                each.selectedAnswer === '' &&
                ((each.type === QuestionEnum.text && each.option_type !== QuestionEnum.audio) || (each.type === QuestionEnum.image && each.option_type !== QuestionEnum.audio))
            )}
          />
        </div>
        <div
          style={{
            maxWidth: '50%',
            flex: '0 0 50%',
            padding: '0 15px',
          }}
        >
          <QuestionWrapper>
            <MusicSvg />
            <H5>읽기 ({result!.questionAns.filter((each) => each.selectedAnswer === '' && each.type === QuestionEnum.audio).length} Questions)</H5>
          </QuestionWrapper>
          <QuestionNumber
            activeHandler={activeHandler}
            allQuestionAns={result!.questionAns.filter(
              (each) => (each.selectedAnswer === '' && each.type === QuestionEnum.audio) || (each.selectedAnswer === '' && each.type === QuestionEnum.imgAudio)
            )}
          />
        </div>
      </div>
    </div>
  )
}
