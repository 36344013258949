import { ResponseUtils, SingleResponseUtils } from "src/types/response/utils";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";


import { _Class } from "src/model/class";

interface ClassResource {
  total: number;
  _class: _Class | null;
  classes: _Class[];
}

const initialState: ClassResource = {
  total: 0,
  _class: null,
  classes: [],
};

export const classSlice = createSlice({
  name: "class",
  initialState,
  reducers: {
    getCourse: (state, action: PayloadAction<ResponseUtils<_Class>>) => ({
      ...state,
      classes: action.payload.data,
      total: action.payload.total,
    }),
  },
});

export const { getCourse } = classSlice.actions;

export default classSlice;
