import React from "react";
import Card from "src/common/card/index";
import { Body1, Body2, H2, H6 } from "src/common/theme";
import { RootWrapper } from "src/common/wrapper/root-wrapper";
import { Button } from "@mui/material";
import NewsImg from "src/common/images/news.webp";
import CovidImg from "src/common/images/covid.webp";
import NewsPaperImg from "src/common/images/newpaper.webp";
import { useEffect } from "react";
import { apiService } from "src/http/api-service";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import { Article, LinkType } from "src/model/article";

const title = "Repurpose mission critical action life items rather linkagesuds";
const date = "25 July, 2022";
const subTitle = "READ MORE DETAILS";

function Index() {
  const dispatch = useAppDispatch();
  const blogs = useAppSelector((state) => state.blog.blogs);

  useEffect(() => {
    const fetchArticle = async () => {
      const res = await apiService.getArticle(dispatch, LinkType.blogLink);
    };

    fetchArticle();
  }, []);

  return (
    <div
      style={{
        padding: "120px 0 50px 0",
        background: "#F3F7FB",
      }}
    >
      <RootWrapper>
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <div>
              <Body1 style={{ fontWeight: "500" }} color="blue">
                OUR NEWS & BLOGS
              </Body1>
              <H2>Latests News & Blogs</H2>
            </div>

            <Button sx={{ padding: "17px 30px" }} variant="contained">
              VIEW MORE NEWS
            </Button>
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
              margin: "70px 0",
            }}
          >
            {blogs && blogs.length > 0 ? (
              blogs.map((blog: Article) => {
                return (
                  <>
                    <Card
                      image={blog.images[0]}
                      title={blog.title}
                      date={blog.createdAt}
                      subTitle={subTitle}
                      url={blog.url}
                    />
                  </>
                );
              })
            ) : (
              <H6>Comming Soon......</H6>
            )}
          </div>
        </>
      </RootWrapper>
    </div>
  );
}

export default Index;
