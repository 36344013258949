import styled from "styled-components";

export const H2 = styled.h2`
  font-weight: 600;
  font-size: 35px;
  line-height: 69px;
  /* text-align: center; */
  color: #ffffff;
`;

export const P = styled.div`
  font-weight: 600;
  font-size: 18px;
  /* line-height: 30px; */
  /* text-align: center; */

  color: #ffffff;
`;
