import { useEffect } from 'react'
import { ExamWrapper } from 'src/common/wrapper/exam-wrapper'
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks'
import { apiService } from 'src/http/api-service'

function MainExam() {
  const result = useAppSelector((state) => state.result.result)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!result) {
      apiService.getExamResult(dispatch, localStorage.getItem('modalSetId')!)
    }
  }, [result, dispatch])

  return <>{result && <ExamWrapper />}</>
}

export default MainExam
