import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 24px 0px;
`;

export const Main = styled.div`
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  min-height: 850px;
  padding: 20px 20px 0px 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 1px 1px 32px 2px rgba(203, 203, 203, 0.25);
`;

export const Container = styled.div`
  height: 560px;
  width: 1195px;
`;

export const ImgDiv = styled.div`
  width: 70px;
  height: 70px;
`;

export const FormWrapper = styled.div`
  margin-bottom: 10px;
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  // border: 1px solid;
  width: 100%;
  max-width: 600px;
`;
