import styled from "styled-components";

export const Wrapper = styled.div`
  height: 167px;
  background: #ffffff;
  box-shadow: 0px 9px 14px rgba(203, 203, 203, 0.25);
  border-radius: 10px;
`;

export const Container = styled.div`
  height: 80px;
  display: flex;
  border-bottom: 2px solid #f5f7fa;
`;

export const ProfileDiv = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 8px 109px 10px;
`;

export const Name = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  margin: 10px 0px 109px 0px;
`;

export const Main = styled.div`
  display: flex;
`;

export const ProfileWrapper = styled.div`
  height: 40px;
  margin: 18px 15px 25px 25px;
`;

export const MyProfile = styled.div`
  height: 40px;
  margin: 18px 0px 0px 0px;
`;
