import React, { InputHTMLAttributes } from "react";

interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

function TextField(props: TextFieldProps) {
  const { label, ...rest } = props;
  return <input {...rest} />;
}

export default TextField;
