import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { paymentHistory } from 'src/model/payment'
import { Result } from 'src/model/result'
import { ResponseUtils, SingleResponseUtils } from 'src/types/response/utils'

interface resultResource {
  result: Result | null
  paidResult: Result[]
  total: number
  paymentHistory: paymentHistory[]
}

const initialState: resultResource = {
  result: null,
  total: 0,
  paidResult: [],
  paymentHistory: [],
}

const resultData = createSlice({
  name: 'result',
  initialState,
  reducers: {
    getExamResult: (state, action: PayloadAction<SingleResponseUtils<Result>>) => ({
      ...state,
      result: action.payload.data,
    }),

    selectedAnswer: (state, action: PayloadAction<SingleResponseUtils<Result>>) => ({
      ...state,
      result: action.payload.data,
    }),

    submitAnswer: (state, action: PayloadAction<SingleResponseUtils<Result>>) => {
      return {
        ...state,
        result: action.payload.data,
      }
    },

    updateResult: (state, action: PayloadAction<SingleResponseUtils<Result>>) => {
      return {
        ...state,
        result: action.payload.data,
      }
    },

    getPaidResult: (state, action: PayloadAction<ResponseUtils<Result>>) => {
      state.paidResult = action.payload.data
    },

    getPaymentHistory: (state, action: PayloadAction<ResponseUtils<paymentHistory>>) => {
      state.paymentHistory = action.payload.data
    },

    updateAudio: (state, action: PayloadAction<SingleResponseUtils<Result>>) => {
      return {
        ...state,
        result: action.payload.data,
      }
    },
  },
})

export const { getExamResult, selectedAnswer, getPaidResult, getPaymentHistory, submitAnswer, updateAudio } = resultData.actions
export default resultData
