import React, { FC } from "react";
import { contactData } from "./ContactData";
import { H4, Body1, H6, Body2, UnderLine } from "src/common/theme";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  margin-top: 100px;
  padding: 50px 100px;
  background: #f0f5fb;
  @media (max-width: 900px) {
    padding: 20px;
  }
  @media (max-width: 900px) {
    padding: 15px;
  }
`;
const DataArray: FC<{ data: typeof contactData[0] }> = ({ data }) => {
  return (
    <div
      style={{
        display: "flex",
        gap: "30px",
        maxWidth: "338px",
        width: "100%",
      }}
    >
      <div
        style={{
          width: "81px",
          height: "81px",
          backgroundColor: `${data.background}`,
        }}
      />
      <div>
        <H6>{data.title}</H6>
        <Body2>{data.info}</Body2>
        <Body2>{data.desc}</Body2>
      </div>
    </div>
  );
};

function index() {
  return (
    <Wrapper>
      <H4>Get in Touch</H4>
      <UnderLine />
      <Body1
        style={{
          maxWidth: "480px",
          marginTop: "20px",
        }}
      >
        Have a inquiry or some feedback for us? Fill out the form below to
        contact our team. please contact us at hello@acadu.com.
      </Body1>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "5px",
          marginTop: "34px",
        }}
      >
        {contactData.map((data) => {
          return <DataArray data={data} key={data.id} />;
        })}
      </div>
    </Wrapper>
  );
}

export default index;
