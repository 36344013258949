import {
  CircularProgress,
  Pagination,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { HeaderFooter } from "src/App";
import { RootWrapper } from "src/common/wrapper/root-wrapper";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import { apiService } from "src/http/api-service";
import DownloadDetail from "./all-download";

const Index = () => {
  const [page, setPage] = useState(1);

  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [sortType, setsortType] = useState("desc");
  const [sortBy, setsortBy] = useState("-createdAt");
  const [select, setselect] = useState<string>("all");

  const [limit, setLimit] = useState(15);
  const dispatch = useAppDispatch();
  const [alignment, setAlignment] = React.useState("all");
  const total = useAppSelector((state) => state.download.total);
  const result = useAppSelector((state) => state.download.result);
  const countType = useAppSelector((state) => state.download.countType);
  const [checkCount, setCheckCount] = useState(0);
  const [counter, setCounter] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);

  const fetchData = async (
    alignment: string = "all",
    rowsPerPage: number = 15,
    page: number = 1,
    sortBy: string = "-createdAt"
  ) => {
    setIsLoading(true);
    const res: any = await apiService.getDownloadable(dispatch, {
      type: alignment,
      rowsPerPage,
      page,
      sortBy,
    });
    if (res) {
      setCheckCount(res.countType);

      if (res.countType > 15) {
        setCounter(Math.ceil(res.countType / limit));
      } else {
        setCounter(1);
      }
    }
    setIsLoading(false);
  };

  const handleToggleChange = async (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setPage(1);
    setAlignment(newAlignment);
    setCounter(1);
    setIsPaginationLoading(true);
    await fetchData(newAlignment, 15, 1);
    setIsPaginationLoading(false);
  };

  const handleChange = async (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    await fetchData(alignment, 15, value);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <HeaderFooter>
      <RootWrapper>
        <Stack justifyContent="flex-end" direction="row">
          <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleToggleChange}
            aria-label="Platform"
            sx={{ marginTop: "10px" }}
          >
            <ToggleButton value="all" disabled={alignment === "all"}>
              All
            </ToggleButton>
            <ToggleButton value="audio" disabled={alignment === "audio"}>
              Audio
            </ToggleButton>
            <ToggleButton value="image" disabled={alignment === "image"}>
              Images
            </ToggleButton>
            <ToggleButton value="video" disabled={alignment === "video"}>
              Video
            </ToggleButton>
            <ToggleButton value="pdf" disabled={alignment === "pdf"}>
              PDF
            </ToggleButton>
            <ToggleButton value="drive" disabled={alignment === "drive"}>
              GOOGLE Drive
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </RootWrapper>
      <DownloadDetail alignment={alignment} isLoading={isLoading} />

      {isPaginationLoading ? (
        <Box
          sx={{
            display: "flex",
            padding: "30px",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Pagination
          sx={{
            display: "flex",
            padding: "30px",
            justifyContent: "center",
          }}
          defaultPage={1}
          count={counter}
          shape="rounded"
          size="large"
          color="primary"
          onChange={handleChange}
        />
      )}
    </HeaderFooter>
  );
};
export default Index;
