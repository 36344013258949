import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Result } from 'src/model/result'
import { SingleResponseUtils } from 'src/types/response/utils'

interface reviewResources {
  review: Result | null
}

const initialState: reviewResources = {
  review: null,
}

const reviewSlice = createSlice({
  name: 'review',
  initialState,

  reducers: {
    getReview: (state, action: PayloadAction<SingleResponseUtils<Result>>) => ({
      ...state,
      review: action.payload.data,
    }),
  },
})

export const { getReview } = reviewSlice.actions
export default reviewSlice
