import moment from "moment";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Body1, Body2, H4, H5, Subtitle1 } from "src/common/theme";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import { apiService } from "src/http/api-service";
import { Container, Date, Wrapper } from "./style/TestStyle";

function Mytest() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const myTest = useAppSelector((state) => state.result.paidResult);

  const { data, isLoading } = useQuery([], () => {
    apiService.getPaidResult(dispatch);
  });

  useEffect(() => {
    const res = apiService.getProfile(dispatch);
    console.log("res", res);
  }, []);

  return (
    <>
      {isLoading ? (
        <H4>Loading...</H4>
      ) : (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            margin: "30px 0",
          }}
        >
          {myTest && myTest.length > 0 ? (
            myTest.map((item, index) => {
              return (
                <Wrapper key={index}>
                  <Container>
                    <Body2
                      style={{
                        background: `${
                          item.modelSet?.type === "free" ? "red" : "blue"
                        }`,
                        color: "white",
                        width: "60px",
                        height: "22px",
                        textAlign: "center",
                        borderRadius: "20px",
                      }}
                    >
                      {item.modelSet?.type.toUpperCase()}
                    </Body2>

                    <H5>{item.modelSet?.title}</H5>
                    <Body1>
                      Score:
                      <span style={{ color: "#000000", fontWeight: "450" }}>
                        {(
                          (item?.correctAnswers! / item?.totalQuestions!) *
                          100
                        ).toFixed(2)}
                      </span>
                    </Body1>
                    <Body1>
                      Percentage:
                      <span style={{ color: "#000000", fontWeight: "450" }}>
                        {(
                          (item?.correctAnswers! / item?.totalQuestions!) *
                          100
                        ).toFixed(2)}
                        %
                      </span>
                    </Body1>
                    <Body1>
                      Status: &nbsp;
                      <span style={{ color: "#000000", fontWeight: "450" }}>
                        {item.isCompleted ? "Completed" : "Incomplete"}
                      </span>
                    </Body1>
                    <Date>
                      <Body2>
                        Date:
                        <span style={{ color: "#000000", fontWeight: "450" }}>
                          {moment(item.createdAt).format("lll")}
                        </span>
                      </Body2>
                      <div
                        style={{
                          textDecorationLine: "underline",
                          color: "#0D5EF4",
                          fontWeight: "500",
                        }}
                      >
                        {item.isCompleted === true ? (
                          <Subtitle1
                            onClick={() =>
                              navigate(`/mytest/${item.id}`, { replace: true })
                            }
                            style={{ color: "#0D5EF4" }}
                          >
                            Review
                          </Subtitle1>
                        ) : (
                          <Subtitle1
                            onClick={() => navigate("/examroom")}
                            style={{ color: "#0D5EF4", cursor: "pointer" }}
                          >
                            Continue
                          </Subtitle1>
                        )}
                      </div>
                    </Date>
                  </Container>
                </Wrapper>
              );
            })
          ) : (
            <H4>No Test Found</H4>
          )}
        </div>
      )}
    </>
  );
}

export default Mytest;
