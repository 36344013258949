import React from "react";
import { RootWrapper } from "src/common/wrapper/root-wrapper";
import { H2, Body1 } from "src/common/theme";
import PhysicalClassImg from "src/common/images/physical-class.webp";
import OnlineClassImg from "src/common/images/online-class.webp";
import { ClassSectionWrapper } from "../style";
import ClassCard from "src/page-components/home-page/book-class/class-card";

const title1 = "Do You Want Physical Class?";
const info =
  "Explore all of  and pick your suitable ones to enroll and start learning with us!";
const title2 = "Do You Want Online Class?";

function index() {
  return (
    <div
      style={{
        margin: "110px 0",
        padding: "74px 0",
        background: "linear-gradient(270deg, #EDF2FB 0%, #FFFFFF 100%)",
        minHeight: "939px",
        width: "100%",
      }}
    >
      <RootWrapper>
        <div
          style={{
            textAlign: "center",
            width: "100%",
          }}
        >
          <Body1 color="blue" style={{ fontWeight: "500" }}>
            BOOK CLASS NOW
          </Body1>
          <H2 style={{ marginTop: "14px" }}>What Are You Looking For?</H2>
          <Body1 style={{ marginTop: "25px" }}>
            We’re running both physical and online classes. Choose what’s best
            for you
          </Body1>

          <ClassSectionWrapper>
            <ClassCard
              paths="/physical-class"
              image={PhysicalClassImg}
              title={title1}
              info={info}
              background="blue"
              color="blue"
              button="REGISTER NOW"
            />

            <ClassCard
              paths="/online-class"
              image={OnlineClassImg}
              title={title2}
              info={info}
              flexDirection="row"
              background="pink"
              color="red"
              button="REGISTER NOW"
            />
          </ClassSectionWrapper>
        </div>
      </RootWrapper>
    </div>
  );
}

export default index;
