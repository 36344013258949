export enum LinkType {
  blogLink = "blogLink",
  publicationLink = "publicationLink",
}
export interface Article {
  LinkType: LinkType;
  contentType: string;
  description: string;
  title: string;
  url: string;
  images: string[];
  videos: string[];
  createdAt: string;
  siteName: string;
}
