import React from "react";
import OnlineClassimg from "src/common/images/online-class.webp";
import { H2 } from "src/common/theme";
import PhysicalClassimg from "src/common/images/physical-class.webp";
import ClassGroup from "../index";
import { HeaderFooter } from "src/App";
import { LeftBar, RightBar } from "src/common/svg";
import { ContactWrapper, LeftBarWrapper, RightBarWrapper } from "./style";

function index() {
  return (
    <HeaderFooter>
      <ContactWrapper>
        <LeftBarWrapper>
          <LeftBar />
        </LeftBarWrapper>
        <RightBarWrapper>
          <RightBar />
        </RightBarWrapper>
        <H2 color="white">CLASSES</H2>
      </ContactWrapper>

      <div>
        <ClassGroup
          paths="/online-class"
          image={OnlineClassimg}
          background="red"
          button="RGISTER NOW"
          flexDirection="row-reverse"
          title="Do You Want online Class?"
          info="Explore all of our courses and pick your suitable ones to enroll and start learning with us!"
        />

        <div
          style={{
            background: "#F3F7FB",
            margin: "0 0 100px 0",
            padding: "5px 0",
          }}
        >
          <ClassGroup
            paths="/physical-class"
            flexDirection="row"
            image={PhysicalClassimg}
            button="REGISTER NOW"
            background="blue"
            title="Do You Want Physical Class?"
            info="Explore all of our courses and pick your suitable ones to enroll and start learning with us!"
          />
        </div>
      </div>
    </HeaderFooter>
  );
}
export default index;
