import styled from "styled-components";

export const AboutWrapper = styled.div`
  display: flex;
  gap: 60px;
  margin: 113px 0;
  width: 100%;
  align-items: center;
  max-width: @media (max-width: 1920px) {
    flex: 0 0 calc(100% - 14px);
    max-width: calc(100% - 14px);
  }
  @media (max-width: 1536px) {
    flex: 0 0 calc(100% - 14px);
    max-width: calc(100% - 14px);
  }
  @media (max-width: 1200px) {
    flex: 0 0 calc(100% - 14px);
    max-width: calc(100% - 14px);
  }
  @media (max-width: 900px) {
    flex-wrap: wrap;
    justify-content: center;
    flex: 0 0 calc(100% - 14px);
    max-width: calc(100% - 14px);
  }
  @media (max-width: 600px) {
    flex: 0 0 calc(100% - 14px);
    max-width: calc(100% - 14px);
  }
`;
