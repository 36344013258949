import { useEffect, useState } from "react";

export const useSocket = () => {
  const [socket, setSocket] = useState<null | WebSocket>(null);

  const connectToServer = async () => {
    // const ws: WebSocket = new WebSocket(
    //   process.env.SOCKET_DEV ?? "wss://angle-backend.herokuapp.com"
    // );

    let ws: WebSocket;

    if (process.env.NODE_ENV === "production") {
      ws = new WebSocket(process.env.SOCKET_DEV ?? "wss://api.angelkorean.com");
    } else {
      ws = new WebSocket(process.env.SOCKET_DEV ?? "ws://localhost:9261");
    }

    return new Promise((resolve, reject) => {
      const timer = setInterval(() => {
        if (ws.readyState === 1) {
          clearInterval(timer);
          resolve(ws);
        }
      }, 1000);
    });
  };

  useEffect(() => {
    if (socket) return;
    (async () => {
      const socket = await connectToServer();
      if (socket) {
        setSocket(socket as any);
      }
    })();
  }, [socket]);

  return socket;
};
