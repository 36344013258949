import React from "react";
import { RootWrapper } from "src/common/wrapper/root-wrapper";
import { Body1, H2 } from "src/common/theme";
import { Button } from "@mui/material";
import { East } from "@mui/icons-material";
import { Navigate, useNavigate } from "react-router-dom";

function Index() {
  const navigate = useNavigate();

  return (
    <div>
      <Body1
        color="blue"
        style={{ textAlign: "left", margin: "15px 0", fontWeight: "500" }}
      >
        MORE ABOUT OUR COMPANY
      </Body1>
      <H2 style={{ textAlign: "left" }}>About Angel Korean</H2>
      <Body1
        color="grey"
        style={{
          marginTop: "20px",
          textAlign: "justify",
          maxWidth: "676px",
        }}
      >
        Angel Korean Language Institute is a Korean Language Institute in Nepal.
        We provide best quality classes to crack your language exams. Hurry up
        and join us now. It is pleasure to welcome you to Angel Korean Language
        Institute.
      </Body1>

      <Button
        variant="outlined"
        endIcon={<East />}
        style={{ margin: "25px 0", padding: "17px 32px" }}
        onClick={() => navigate("/about")}
      >
        ABOUT US
      </Button>
    </div>
  );
}

export default Index;
