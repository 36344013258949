import React, { useState } from "react";
import { Person } from "src/common/svg";
import { RootWrapper } from "src/common/wrapper/root-wrapper";
import Mytest from "./Mytest";
import MyProfile from "./MyProfile";
import MyPayment from "./MyPayment";
import "../../index.css";
import { H4 } from "src/common/theme";

import {
  Container,
  Main,
  ProfileWrapper,
  Name,
  ProfileDiv,
  Wrapper,
} from "./style/profileStyle";

import { HeaderFooter } from "src/App";
import { useAppSelector } from "src/hooks/hooks";

function Profile() {
  const user = useAppSelector((state) => state.student.profile);
  const [activeTab, setActiveTab] = useState(0);

  const handleTab = (index: number) => {
    setActiveTab(index);
  };

  return (
    <HeaderFooter>
      <div style={{ background: "#F3F7FB", padding: "80px 0" }}>
        <RootWrapper>
          <Wrapper>
            <Container>
              <ProfileDiv>
                <Person />
              </ProfileDiv>
              <Name>
                <H4>{user?.name}</H4>
              </Name>
            </Container>
            <Main>
              {["My Test", "My Profile"].map((item, index) => {
                return (
                  <ProfileWrapper key={index}>
                    <ul className="Nav">
                      <li
                        className={activeTab === index ? "active" : ""}
                        onClick={() => handleTab(index)}
                      >
                        {item}
                      </li>
                    </ul>
                  </ProfileWrapper>
                );
              })}
            </Main>
          </Wrapper>
          {activeTab === 0 && <Mytest />}
          {activeTab === 1 && <MyProfile />}
          {activeTab === 2 && <MyPayment />}
        </RootWrapper>
      </div>
    </HeaderFooter>
  );
}

export default Profile;
