import React from "react";
import "../../index.css";
import { Main, Wrapper } from "../contact/style/index";
import { H2, UnderLine, Body1 } from "src/common/theme";

import OnlineForm from "./OnlineForm";
import { RootWrapper } from "src/common/wrapper/root-wrapper";
import { HeaderFooter } from "src/App";

function index() {
  return (
    <HeaderFooter>
      <RootWrapper>
        <Main>
          <Wrapper>
            <Body1 color="blue" style={{ fontWeight: "500" }}>
              ONLINE CLASS
            </Body1>
            <H2>Please fill up the form</H2>
            <UnderLine />
            <OnlineForm />
          </Wrapper>
        </Main>
      </RootWrapper>
    </HeaderFooter>
  );
}
export default index;
