import styled from "styled-components";

export const Footer = styled.div`
  min-height: 300px;
  width: 100%;
  // border: 1px solid red;
  background: #0a2d6f;
  color: #ffffff;
`;

export const Box = styled.div`
  // min-height: 53px;
  /* display: flex; */
  display: block;
  flex-wrap: wrap;
  width: 100%;
//  border: 1px solid red;

  max-width: fit-content;
  @media (max-width: 1200px) {
    margin-top: 15px;
  }
  @media (max-width: 600px) {
    margin-top: 15px;
  }
`;

export const Stack = styled.div`
  display: flex;
  /* column-gap: 50px; */
  /* justify-content: space-around; */
  flex-wrap: wrap;
  @media (max-width: 1200px) {
    margin-top: 15px;
  }
  @media (max-width: 600px) {
    margin-top: 15px;
  }
  @media (max-width: 375px) {
    margin-top: 10px;
  }
`;

export const Main = styled.div`
  /* border: 1px solid red; */
  /* margin: 10px 0; */
  display: flex;
  flex-direction: column;
  row-gap: 9px;
`;

export const Container = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  /* column-gap: 45px; */
  border-bottom: 1px solid #224d9c;
  @media (max-width: 1200px) {
    flex-direction: column-reverse;
  }
  @media (max-width: 600px) {
    /* flex-direction: column-reverse; */
  }
  @media (max-width: 375px) {
    flex-direction: column-reverse;
  }
`;


export const CompanyInfoWrapper = styled.div`
padding: 50px 0 15px 0;
width: 100%;
max-width: 730px;
border-left: 1px solid #224D9C;
// border: 1px solid white;
`