import styled from "styled-components";

export const Wrapper = styled.div`
  background: #fffff;
  @media (max-width: 1920px) {
    flex: 0 0 calc(33.33% - 14px);
    max-width: calc(33.33% - 14px);
  }
  @media (max-width: 1536px) {
    flex: 0 0 calc(33.33% - 14px);
    max-width: calc(33.33% - 14px);
  }
  @media (max-width: 1200px) {
    flex: 0 0 calc(50% - 14px);
    max-width: calc(50% - 14px);
  }
  @media (max-width: 900px) {
    flex: 0 0 calc(50%% - 14px);
    max-width: calc(50%% - 14px);
  }
  @media (max-width: 600px) {
    flex: 0 0 calc(100% - 14px);
    max-width: calc(100% - 14px);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  width: 100%;
  padding: 30px;
  background: #ffffff;
  border: 1px solid #ecf1f9;
  border-radius: 10px;
  // box-shadow: 0px 9px 14px rgba(203, 203, 203, 0.25);
`;

export const Date = styled.div`
  display: flex;
  justify-content: space-between;
`;
