import WelcomeImg from "src/common/images/welcome-img.webp";
import { Body1, H2 } from "src/common/theme";
import { RootWrapper } from "src/common/wrapper/root-wrapper";
import { AboutWrapper } from "../style";

function index() {
  return (
    <RootWrapper
      style={{
        position: "relative",
      }}
    >
      <AboutWrapper>
        <div
          style={{
            height: "100%",
            width: "100%",
            maxWidth: "663px",
            objectFit: "cover",
          }}
        >
          <img
            src={WelcomeImg}
            alt="welcome section image"
            height="100%"
            width="100%"
          />
        </div>
        <div
          style={{
            height: "100%",
            width: "100%",
            maxWidth: "600px",
            display: "flex",
            flexDirection: "column",
            rowGap: "20px",
            flexShrink: "1",
          }}
        >
          <Body1
            color="blue"
            style={{
              fontWeight: "600",
              width: "100%",
            }}
          >
            ABOUT ANGEL KOREAN
          </Body1>
          <H2>Welcome to Angel Korean Bhasa</H2>

          <Body1 color="grey" style={{ textAlign: "justify" }}>
            Angel Korean Language Institute is a Korean Language Institute in
            Nepal. We provide best quality classes to crack your language exams.
            Hurry up and join us now.
            <br />
            It is pleasure to welcome you to Angel Korean Language Institute. We
            are a Korean Language Institute in Nepal. We provide best quality
            classes to crack your language exams. Hurry up and join us now.
          </Body1>
        </div>
      </AboutWrapper>
    </RootWrapper>
  );
}

export default index;
