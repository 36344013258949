/**
 * TODO:
 * Trigger --> Exam start
 * Event -> Specific
 * Seperate File Placement -> Reuseable
 * Every 10 Seconds send the timers count
 */

function Timer(props: any) {
  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ fontSize: "18x", fontWeight: "600" }}>
        <span>{props.countTimer.minutes}</span>:
        <span>{props.countTimer.seconds}</span>
      </div>
    </div>
  );
}

export default Timer;
