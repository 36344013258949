import { Download, Folder, Image, MusicNote, VideoLibrary } from '@mui/icons-material'
import { Box, CircularProgress, Grid, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { RootWrapper } from 'src/common/wrapper/root-wrapper'
import { useAppSelector } from 'src/hooks/hooks'

interface propsType {
  alignment: string
  isLoading: boolean
}

const Index = (props: propsType) => {
  const [secondary, setSecondary] = React.useState(false)

  const downloadable = useAppSelector((state) => state.download.download)
  const total = useAppSelector((state) => state.download.total)
  const countType = useAppSelector((state) => state.download.countType)

  return (
    <RootWrapper>
      <Box
        sx={{
          mt: 3,
          width: '100%',
          overflow: 'hidden',
          minHeight: '400px',
          backgroundColor: 'background.paper',
        }}
      >
        <Grid container columnSpacing={2}>
          <Grid item md={12}>
            <Typography variant="h6" color="primary" component="h6" gutterBottom marginLeft={2}>
              Downloadable Links for {props.alignment || 'All Category'}{' '}
              <span
                style={{
                  color: 'red',
                  fontWeight: 'bolder',
                }}
              >
                ({props.alignment === 'all' ? total : countType})
              </span>
            </Typography>
          </Grid>

          {!props.isLoading ? (
            downloadable && downloadable.length > 0 ? (
              downloadable.map((item: any, index: Number) => {
                return (
                  <Grid item sm={6} xs={12} md={4}>
                    <ListItem>
                      <ListItemIcon>{item.file_type === 'audio' ? <MusicNote /> : item.file_type === 'image' ? <Image /> : item.file_type === 'video' ? <VideoLibrary /> : <Folder />}</ListItemIcon>
                      <ListItemText primary={item.title} secondary={secondary ? 'Secondary text' : null} />
                      <a href={item.link} target="_blank" rel="noreferrer">
                        <Tooltip title="download">
                          <Download color="error" sx={{ cursor: 'pointer' }} />
                        </Tooltip>
                      </a>
                    </ListItem>
                  </Grid>
                )
              })
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Typography variant="h6" color="primary" component="h6" gutterBottom marginLeft={2}>
                  No Downloadable Links for {props.alignment || 'All Category'}{' '}
                </Typography>
              </Box>
            )
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Grid>
      </Box>
    </RootWrapper>
  )
}

export default Index
