import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ModelSet } from 'src/model/modelSet'
import { ResponseUtils } from 'src/types/response/utils'

interface UserResource {
  total: number
  result?: number
  countType?: number
  exams: ModelSet[]
}

const initialState: UserResource = {
  total: 0,
  result: 0,
  countType: 0,
  exams: [],
}

const modelSetSlice = createSlice({
  name: 'modelSet',
  initialState,
  reducers: {
    getExamModel: (state, action: PayloadAction<ResponseUtils<ModelSet>>) => ({
      ...state,
      total: action.payload.total,
      result: action.payload.results,
      countType: action.payload.countType,
      exams: action.payload.data,
    }),
    getFeaturedModalSet: (state, action: PayloadAction<ResponseUtils<ModelSet>>) => ({
      ...state,
      exams: action.payload.data,
    }),
  },
})

export const { getExamModel, getFeaturedModalSet } = modelSetSlice.actions
export default modelSetSlice
