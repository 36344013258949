import React from "react";
import { RootWrapper } from "src/common/wrapper/root-wrapper";
import ContactWithUs from "./contact-with-us";
import GetInTouch from "./get-in-touch";
import { H2 } from "src/common/theme";
import { RightBar, LeftBar } from "src/common/svg";
import { ContactWrapper, RightBarWrapper, LeftBarWrapper } from "./style";
import { HeaderFooter } from "src/App";

function index() {
  return (
    <HeaderFooter>
      <ContactWrapper>
        <LeftBarWrapper>
          <LeftBar />
        </LeftBarWrapper>
        <RightBarWrapper>
          <RightBar />
        </RightBarWrapper>
        <H2 color="white">CONTACT</H2>
      </ContactWrapper>
      <RootWrapper>
        <GetInTouch />
        <ContactWithUs />
      </RootWrapper>
    </HeaderFooter>
  );
}

export default index;
