import { createSlice } from '@reduxjs/toolkit'

import { Publication } from 'src/model/publication'

interface articleResource {
  total: number
  publication: Publication[]
}

const initialState: articleResource = {
  total: 0,
  publication: [],
}

export const publicationSlice = createSlice({
  name: 'publication',
  initialState,
  reducers: {
    getPublication: (state, action: any) => {
      return {
        ...state,
        total: state.total,
        publication: action.payload.data,
      }
    },

    // deletearticle: (state, action: any) => {
    //   const articles = [...state.articles];
    //   const updatedarticles = articles.filter(
    //     (article) => article.id !== action.payload
    //   );

    //   return {
    //     ...state,
    //     articles: updatedarticles,
    //   };
    // },
  },
})

export const { getPublication } = publicationSlice.actions

export default publicationSlice
