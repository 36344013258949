import { FacebookIconSvg, YouTubeIconSvg } from 'src/common/svg'
import { Body1, Subtitle1 } from 'src/common/theme'
import { Box } from '../style'
function index() {
  return (
    <>
      <div
        style={{
          // borderRight: "1px solid #224D9C",
          padding: '50px 0 15px 0',
          maxWidth: '480px',
          width: '100%',
          paddingRight: '40px',
          // border: "1px solid red",
        }}
      >
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            // border: '1px solid white',

            justifyContent: 'space-between',
            // marginRight: '40px'
          }}
        >
          <Box>
            <Subtitle1 color="white" style={{ marginBottom: '10x' }}>
              CALL US NOW
            </Subtitle1>
            <Body1>
              <a href="tel:+(977) 9848254922" style={{ color: 'white', marginTop: '5px' }}>
                {' '}
                (+977) 9848254922
              </a>
            </Body1>
          </Box>
          <Box>
            <Subtitle1 color="white" style={{ marginBottom: '10x' }}>
              CONTACT US VIA EMAIL
            </Subtitle1>
            <Body1>
              <a href="mailto: angelkorean@gmail.com" style={{ color: 'white', marginTop: '5px' }}>
                angelkorean@gmail.com
              </a>{' '}
            </Body1>
          </Box>
        </div>
        <div
          style={{
            flexDirection: 'column',
            marginTop: '50px',
          }}
        >
          <Subtitle1 color="white">FOLLOW US ON SOCIAL MEDIA</Subtitle1>
          <Box
            style={{
              minWidth: '190px',
              display: 'flex',
              marginTop: '10px',
              gap: '10px',
              cursor: 'pointer',
              // justifyContent: "space-between",
            }}
          >
            <a href="https://www.facebook.com/angelkoreanbhasa" target="_blank" rel="noreferrer">
              <FacebookIconSvg />{' '}
            </a>
            <a href="https://www.youtube.com/channel/UCRcMIdiOGSOP5nOnsoMQVpQ" target="_blank" rel="noreferrer">
              <YouTubeIconSvg />
            </a>
            {/* <TwitterIconSvg /> */}
            {/* <LinkedInIconSvg /> */}
            {/* <PinterestSvg /> */}
          </Box>
        </div>
      </div>
    </>
  )
}
export default index
