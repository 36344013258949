import styled from "styled-components";

export const ClassSectionWrapper = styled.div`
  display: flex;
  column-gap: 24px;
  margin-top: 70px;
  // border: 1px solid red;
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    row-gap: 50px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  position: relative;
  width: 100%;
  background: #ffffff;
  padding: 30px;
  border: 1px solid #ecf1f9;
  border-radius: 10px;
`;

export const PracticeNowButton = styled.button`
  cursor: pointer;
  width: 100%;
  height: 45px;
  max-width: 269.75px;
  // padding: 4px 16px;
  border-radius: 300px;
  // margin-top: 10px;
  border: none;
  background-color: ${(props) =>
    props.color === "red" ? "#F20F10" : "#0D5EF4"};
`;

export const FeaturedCardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 21px;
  margin-top: 50px;
  // justify-content: space-between;
  @media (max-width: 1200px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const Wrapper = styled.div`
  // border: 1px solid red;
  @media (max-width: 1920px) {
    // margin: 0 7px 15px;
    flex: 0 0 calc(25% - 14px);
    max-width: calc(25% - 14px);
  }
  @media (max-width: 1536px) {
    flex: 0 0 calc(25% - 14px);
    max-width: calc(25% - 14px);
  }
  @media (max-width: 1200px) {
    // margin: 0 7px 15px;
    flex: 0 0 calc(33.33% - 14px);
    max-width: calc(33.33% - 14px);
  }
  @media (max-width: 900px) {
    // margin: 0 7px 15px;
    flex: 0 0 calc(50% - 14px);
    max-width: calc(50% - 14px);
  }
  @media (max-width: 600px) {
    // margin: 0 7px 15px;
    flex: 0 0 calc(100% - 14px);
    max-width: calc(100% - 14px);
  }
`;

export const Box = styled.div`
  max-width: 1379px;
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
  @media (max-width: 1200px) {
    padding: 0 24px;
  }
  @media (max-width: 600px) {
    padding: 0 16px;
  }
`;
