import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  // padding: 50px;
  height: 475px;
  background: #ffffff;
  border-radius: 10px;
  margin: 80px auto;
`;
export const Main = styled.div`
  max-width: 450px;
  width: 100%;
  // height: 442px;
  display: flex;
  // border: 1px solid red;
  flex-direction: column;
  align-items: center;
  // flex-wrap: wrap;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  display: grid;
  // border: 1px solid green;
  row-gap: 5px;
`;
export const Container = styled.div`
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // max-width: 445px;
  // height: 250px;
  // column-gap: 10px;
  // gap: 10px;
  border: 1px solid #e6e6e6;
  padding: 10px;
  border-radius: 5px;
  margin: 18px 0;
`;

export const FormWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 15px;
  // border: 1px solid red;
  justify-content: space-between;
  max-width: 420px;
  // height: 217px;
`;
export const Div = styled.div``;
