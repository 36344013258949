import styled from 'styled-components'

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 90px 0 0 0;
`

export const Textdiv = styled.div`
  margin: 10px 0;
  display: flex;
  gap: 55px;
`

export const Btn = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  font-weight: 500;
  font-size: 18px;
  text-align: right;
`

export const Wrapper = styled.div`
  @media (max-width: 1920px) {
    // margin: 0 7px 15px;
    flex: 0 0 calc(25% - 14px);
    max-width: calc(25% - 14px);
  }
  @media (max-width: 1536px) {
    flex: 0 0 calc(25% - 14px);
    max-width: calc(25% - 14px);
  }
  @media (max-width: 1200px) {
    // margin: 0 7px 15px;
    flex: 0 0 calc(33.33% - 14px);
    max-width: calc(33.33% - 14px);
  }
  @media (max-width: 900px) {
    // margin: 0 7px 15px;
    flex: 0 0 calc(50% - 14px);
    max-width: calc(50% - 14px);
  }
  @media (max-width: 600px) {
    // margin: 0 7px 15px;
    flex: 0 0 calc(100% - 14px);
    max-width: calc(100% - 14px);
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  width: 100%;
  border: 1px solid #ecf1f9;
  background: #ffffff;
  padding: 30px;
  box-shadow: 0px 9px 14px rgba(203, 203, 203, 0.25);
  border-radius: 10px;
`

export const Content = styled.div`
  display: flex;
  // border: 1px solid red;
  flex-direction: column;
  row-gap: 9px;
  width: 260px;
  height: 204px;
`

export const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 24px;
  background: #f20f10;
  border-radius: 100px;
`
