import styled from 'styled-components'

export const Wrapper = styled.div`
  min-height: 160px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  // border: 1px solid red;
  justify-content: space-between;

  @media (max-width: 900px) {
  }
`

export const Navbar = styled.div`
  min-height: 45px;
  width: 100%;
  background: #00306e;
  color: #ffffff;
`

export const IconWrapper = styled.div`
  min-width: 50px;
  display: flex;
  align-items: center;
  // border: 1px solid red;
  justify-content: space-between;
`

export const Stack = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 45px;
  max-width: 1379px;
  justify-content: space-between;
  @media (max-width: 700px) {
    width: 100%;
    display: flex;
  }
`

export const Box = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 2;
  column-gap: 15px;
  align-items: center;
  width: fit-content;
  @media (max-width: 500px) {
    min-height: 45px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`

export const Section = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  flex-shrink: 1;
  justify-content: space-between;
  @media (max-width: 700px) {
    display: none;
  }
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  line-height: 30px;
  gap: 12px;
  margin-bottom: 4px;

  /* @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
`

export const EnrollButton = styled.div`
  min-width: 152px;
  cursor: pointer;
  display: grid;
  place-items: center;
  min-height: 35px;
  background: #ffffff;
  border-radius: 5px;
  color: #00306e;
  &:active {
    background: grey;
  }
`

export const Div = styled.div`
  display: flex;
  gap: 20px;
`

export const DateWrapper = styled.div`
  display: flex;
  column-gap: 20px;
  @media (max-width: 1000px) {
    display: none;
  }
`

export const NavWrapper = styled.div`
  display: flex;
  font-size: 14px;
  gap: 25px;
  @media (max-width: 900px) {
    display: none;
  }
`

export const MenuWrapper = styled.div`
  display: none;
  @media (max-width: 900px) {
    display: block;
  }
`
