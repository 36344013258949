import { H2, Body1 } from "src/common/theme";
import styled from "styled-components";
import { Button } from "@mui/material";
import { AboutWrapper } from "../style";
import { RootWrapper } from "src/common/wrapper/root-wrapper";
import { useNavigate } from "react-router-dom";

function Index() {
  const navigate = useNavigate();

  return (
    <RootWrapper>
      <AboutWrapper>
        <div
          style={{
            width: "100%",
            maxWidth: "678px",
            height: "485px",
            objectFit: "cover",
          }}
        >
          <iframe
            height="100%"
            width="100%"
            src="https://www.youtube.com/embed/6hRDhScSehE"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div
          style={{
            width: "100%",
            maxWidth: "500px",
          }}
        >
          <H2 style={{ margin: "30px 0" }}>Learn Korean Language</H2>
          <Body1 color="grey" style={{ textAlign: "justify" }}>
            Angel Korean Language Institute is a Korean Language Institute in
            Nepal. We provide best quality classes to crack your language exams.
            Hurry up and join us now.
          </Body1>

          <Button
            variant="contained"
            onClick={() => navigate("/exam")}
            style={{ marginTop: "30px", padding: "17px 48px" }}
          >
            TAKE EXAM
          </Button>
        </div>
      </AboutWrapper>
    </RootWrapper>
  );
}

export default Index;
