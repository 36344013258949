import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Download } from "src/model/download";
import { ResponseUtils } from "src/types/response/utils";

interface DownloadResource {
  total: number;
  result: number;
  countType: number;
  download: Download[];
}

const initialState: DownloadResource = {
  total: 0,
  result: 0,
  countType: 0,
  download: [],
};

export const downloadSlice = createSlice({
  name: "download",
  initialState,
  reducers: {
    getDownload: (state, action: any) => ({
      ...state,
      download: action.payload.data,
      result: action.payload.results,
      countType: action.payload.countType,
      total: action.payload.total,
    }),
  },
});

export const { getDownload } = downloadSlice.actions;

export default downloadSlice;
