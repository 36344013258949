import { ModelSet } from 'src/model/modelSet';
import { ResponseUtils, SingleResponseUtils } from 'src/types/response/utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TabletModeResource {
  total: number;
  tabletMode: ModelSet[];
}

const initialState: TabletModeResource  = {
  total: 0,
  tabletMode: [],
};

export const tabletModeSlice = createSlice({
  name: 'tabletMode',
  initialState,
  reducers: {
    getTabletMode: (state, action) => {
      return {
        ...state,
        total: state.total,
        tabletMode: action.payload.data,
      };
      },  
  },
});

export const { getTabletMode } =
  tabletModeSlice.actions;

export default tabletModeSlice;
