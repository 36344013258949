import React from "react";
import ContactFormImage from "src/common/images/contact-form-bg-image.png";
import "../../index.css";
import { Main, Wrapper } from "../contact/style/index";
import { Body1, H2, UnderLine } from "src/common/theme";
import PhysicalForm from "./PhysicalForm";
import { RootWrapper } from "src/common/wrapper/root-wrapper";
import { HeaderFooter } from "src/App";

function index() {
  return (
    <HeaderFooter>
      <RootWrapper>
        <Main>
          <Wrapper>
            <Body1 color="blue" style={{ fontWeight: "500" }}>
              PHYSICAL CLASS
            </Body1>
            <H2>Please fill up the form</H2>
            <UnderLine style={{ marginTop: "10px" }} />
            <PhysicalForm />
          </Wrapper>
        </Main>
      </RootWrapper>
    </HeaderFooter>
  );
}
export default index;
