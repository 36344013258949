import { RootWrapper } from "src/common/wrapper/root-wrapper";
import AboutSection from "../about-angel-korean/about-section/index";
import AboutImg from "src/common/images/about-angel-korean-img.webp";
import styled from "styled-components";

const ImageWrapper = styled.div`
  position: relative;
  height: 708px;
  width: 100%;
  max-width: 608px;
  // border: 1px solid black;
  margin-top: 60px;
  margin-bottom: -170px;
  object-fit: cover;
  @media (max-width: 900px) {
    display: flex;
    flex-direction: columns;
  }
  @media (max-width: 700px) {
    display: none;
    // flex-direction: columns;
  }
`;

function index() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "30px 0",
        columnGap: "60px",
        alignItems: "center",
      }}
    >
      <ImageWrapper>
        <img
          src={AboutImg}
          alt="image of about angel korean"
          height="100%"
          width="100%"
        />
      </ImageWrapper>

      <RootWrapper>
        <AboutSection />
      </RootWrapper>
    </div>
  );
}

export default index;
