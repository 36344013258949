export const contactData = [
  {
    id: 1,
    background: "#0D5EF4",
    title: "Our Address",
    info: "Bagbaazar, Kathmandu ",
    desc: "Nepal",
  },
  {
    id: 2,
    background: "rgba(13, 94, 244, 0.07)",
    title: "Phone Number",
    info: "Mobile: 9848254922",
    desc: "Mail:angelkoreanbhasha@gmail.com",
  },

  {
    id: 3,
    background: "rgba(13, 94, 244, 0.07)",
    title: "Hours of Operation",
    info: "Monday - Friday: 09:00 - 20:00",
    desc: "Sunday & Saturday: 10:30 - 22:00",
  },
];
