import React, { FC, useEffect, useState } from "react";
import { Container } from "../../style";
import moment from "moment";
import {
  NepalFlagSvg,
  CalenderSvg,
  ClockSvg,
  TemperatureSvg,
} from "src/common/svg";
import { H6, Body1 } from "src/common/theme";
import { useAppDispatch } from "src/hooks/hooks";
const NepaliDate = require("nepali-date");

// const url = "https://nepal-weather-api.herokuapp.com/api/?place=all";

function NepalDate() {
  const [weather, setWeather] = useState({} as any);
  const dispatch = useAppDispatch();

  const getWeather = async () => {
    const response = await fetch(
      "https://api.openweathermap.org/data/2.5/weather?q=kathmandu&appid=18e3a52f103534840fce989cf3381180&units=metric"
    );
    setWeather(await response.json());
  };

  useEffect(() => {
    getWeather();
  }, []);

  const d1 = new NepaliDate() as any;

  return (
    <div>
      <Container style={{ borderBottom: "1.5px solid #E5E5E5" }}>
        <NepalFlagSvg />
        <H6 color="black">Kathmandu, Nepal</H6>
      </Container>
      <Container>
        <CalenderSvg />
        <Body1 style={{ fontWeight: 450 }}>
          {d1.format("mmmm d, yyyy ddd")}
        </Body1>
      </Container>
      <Container>
        <Container>
          <ClockSvg />
          <Body1 style={{ fontWeight: 450 }}>{moment().format("LT")}</Body1>
        </Container>
        <Container>
          <TemperatureSvg />
          <Body1 style={{ fontWeight: 450 }}>{weather?.main?.temp} C</Body1>
        </Container>
      </Container>
    </div>
  );
}

export default NepalDate;
