import styled from "styled-components";

export const RootWrapper = styled.div`
  max-width: 1379px;
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
  position: relative;
  @media (max-width: 1200px) {
    width: 100%;
    margin: auto;
    padding: 0 24px;
  }
  @media (max-width: 900px) {
    margin: auto;
    width: 100%;
    padding: 0 15px;
  }
  @media (max-width: 600px) {
    margin: auto;
    width: 100%;
    padding: 0 15px;
  }
  @media (max-width: 375px) {
    margin: auto;
    width: 100%;
    padding: 0 10px;
  }
`;
