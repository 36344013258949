import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { apiService } from "src/http/api-service";
import Esewa from "src/common/images/esewa-icon.png";
import Khalti from "src/common/images/khalti-icon.png";
import moment from "moment";
import { Body1, H6, H4, UnderLine } from "src/common/theme";

import {
  Wrapper,
  Container,
  Box,
  Title,
  Score,
  Date,
} from "./style/PaymentStyle";
import { useAppSelector } from "src/hooks/hooks";
import { useQuery } from "react-query";

function MyPayment() {
  const dispatch = useDispatch();
  const id = localStorage.getItem("id");
  const paymentHistory = useAppSelector((state) => state.result.paymentHistory);

  useEffect(() => {
    apiService.getPaymentHistory(dispatch);
  }, []);

  const { data, isLoading, error } = useQuery(
    [id, dispatch],
    () => apiService.getPaymentHistory(dispatch),
    {
      retry: 3,
    }
  );

  return (
    <>
      {data ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {paymentHistory.length > 0 &&
            paymentHistory.map((history) => {
              return (
                <Wrapper>
                  <Container>
                    <Box>
                      <Title>
                        <H6 color="black">{history.paid_for}</H6>
                      </Title>
                      <Score>
                        <Body1>
                          Amount:&nbsp;
                          <span style={{ color: "#F20F10", fontWeight: "450" }}>
                            Rs {history.amount}
                          </span>
                        </Body1>
                      </Score>
                      <Score>
                        <Body1>
                          <span>Payment Method:&nbsp;</span>
                          <span style={{ color: "#000000", fontWeight: "450" }}>
                            {history.payment_method === "esewa" ? (
                              <div
                                style={{
                                  width: "fit-content",
                                  display: "inline",
                                }}
                              >
                                <img src={Esewa} height={20} />
                              </div>
                            ) : (
                              <div>
                                <img src={Khalti} />
                              </div>
                            )}
                          </span>
                        </Body1>
                      </Score>
                      <UnderLine />
                      <Date>
                        <Body1>
                          Date:&nbsp;
                          <span style={{ color: "#000000", fontWeight: "450" }}>
                            {moment(history.payment_date).format("lll")}
                          </span>
                        </Body1>
                      </Date>
                    </Box>
                  </Container>
                </Wrapper>
              );
            })}
        </div>
      ) : isLoading ? (
        <H4 style={{ margin: "40px 0" }}>Loading...</H4>
      ) : (
        <H4 style={{ margin: "40px 0" }}>No payment found.</H4>
      )}
    </>
  );
}

export default MyPayment;
