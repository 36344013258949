import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: fit-content;
  margin: 24px 0px;
  /* margin-bottom: 85px; */
  //  border: 1px solid red;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  // border: 1px solid red;
  align-items: center;
  width: 100%;
  justify-content: center;
  max-width: 330px;
  height: 175px;
  background: #ffffff;
  border-radius: 10px;
  // margin-bottom: 24px;
  box-shadow: 1px 1px 32px 2px rgba(203, 203, 203, 0.25);
`;
export const Box = styled.div`
  width: 360px;
  height: 144px;
  /* border: 1px solid red; */
`;
export const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 24px;
  background: #f20f10;
  border-radius: 100px;
  margin-bottom: 7px;
`;
export const Title = styled.div`
  margin-bottom: 7px;
`;
export const Score = styled.div`
  margin-bottom: 10px;
`;

export const Date = styled.div`
  /* border: 1px solid red; */
`;
