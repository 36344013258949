import React from "react";
import { RootWrapper } from "src/common/wrapper/root-wrapper";
import { H2, P } from "./style";
// interface
const data = [
  {
    title: "3.092+",
    info: "Successflly Trained",
  },
  {
    title: "15,485+",
    info: "Classes Completed",
  },
  {
    title: "97.55+",
    info: "Satisfaction Rate",
  },
];
function index() {
  return (
    <div
      style={{
        textAlign: "center",
        height: "auto",
        position: "relative",
        // top: '-170px',
        // bottom: "170px",
        background: "#0D5EF4",
      }}
    >
      <RootWrapper>
        <div
          style={{
            display: "flex",
            // border: '1px solid red',
            flexWrap: "wrap",
            // position: 'relative',
            justifyContent: "space-between",
            padding: "3.5rem",
            width: "100%",
          }}
        >
          {data.map((item) => {
            return (
              <div
                style={{
                  borderRight: "1px solid #2F78FF",
                  paddingRight: "3.75rem",
                }}
              >
                <H2>{item.title}</H2>
                <P>{item.info}</P>
              </div>
            );
          })}
        </div>
      </RootWrapper>
    </div>
  );
}

export default index;
