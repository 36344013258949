import styled from "styled-components";

export const ClassWrapper = styled.div`
  // marginTop: 200px;
  // display: grid;
  // placeItems: center;
  // minHeight: 500px;
  // width: 100%;
  padding: 100px;
  // backgroundSize: cover;
  border: 1px solid red;
  // background: #00306E;
  color: #ffffff;
`;

export const ContactWrapper = styled.h2`
  margin-top: 20px;
  display: grid;
  position: relative;
  place-items: center;
  height: 300px;
  width: 100%;
  background: #00306e;
  color: #ffffff;

  @media (max-width: 1200px) {
    height: 200px;
  }
  @media (max-width: 600px) {
    height: 100px;
  }
`;

export const LeftBarWrapper = styled.div`
  width: 194px;
  height: 215px;
  position: absolute;
  top: 0;
  left: 0;
  @media (max-width: 800px) {
    width: 150px;
    height: 171px;
    position: absolute;
    top: 0;
    left: 0;
  }
  @media (max-width: 600px) {
    width: 100px;
    height: 79px;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const RightBarWrapper = styled.div`
  width: 166px;
  height: 165px;
  position: absolute;
  top: 100px;
  right: 0;
  @media (max-width: 800px) {
    width: 122px;
    height: 143px;
    position: absolute;
    top: 0;
    right: 0;
  }
  @media (max-width: 600px) {
    width: 72px;
    height: 94px;
    position: absolute;
    top: 0;
    right: 0;
  }
`;
