import React, { useState } from "react";
import { Main, Wrapper } from "../style";
import { Body1, H2, UnderLine } from "src/common/theme";
import { TextField, Stack, styled, Button } from "@mui/material";
import { East } from "@mui/icons-material";
import { useFormik } from "formik";
import * as yup from "yup";
import { apiService } from "src/http/api-service";
import { toast } from "react-toastify";

const StyledTextField = styled(TextField)(({ theme }) => ({
  margin: "0 7px 15px",
  flex: "0 0 calc(50% - 14px)",
  maxWidth: "calc(50% - 14px)",
  " input": {
    background: `#fff`,
  },
}));

const validationSchema = yup.object({
  name: yup.string().required("Name field is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email field is required"),
  course: yup.string().required("Course field is required"),
  phone: yup
    .string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Enter field a valid phone"
    )
    .required("Phone Number field is required"),
  message: yup.string().required("Message field is required"),
});

export default function ContactUs() {
  const { values, handleChange, touched, handleSubmit, errors } = useFormik({
    initialValues: {
      name: "",
      email: "",
      course: "",
      phone: "",
      message: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      try {
        await apiService.contactUs(values);
        setIsLoading(false);
        toast("Subscribed Successfully!", {
          type: "success",
        });
        resetForm();
      } catch (err: any) {
        setIsLoading(false);
        err.errors?.map((e: any) =>
          toast(e.message, {
            type: "error",
          })
        );
        resetForm();
      }
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <Main>
        <Wrapper>
          <Body1 style={{ fontWeight: "500", marginLeft: "10px" }} color="blue">
            CONTACT WITH US!
          </Body1>
          <H2 style={{ margin: "15px 0 15px 7px" }}>Have Any Questions?</H2>
          <UnderLine style={{ margin: "15px 0 15px 7px" }} />
          <Body1
            style={{
              maxWidth: "500px",
              margin: "15px 0 15px 7px",
              marginBottom: "15px",
            }}
          >
            Enter the following details to contact us!
          </Body1>
          <form onSubmit={handleSubmit}>
            <Stack direction="row" flexWrap="wrap">
              <StyledTextField
                id="name"
                type="text"
                name="name"
                label="Your Name*"
                variant="outlined"
                value={values.name}
                onChange={handleChange}
                error={touched.name && Boolean(touched.name)}
                helperText={touched.name && errors.name}
              />
              <StyledTextField
                id="email"
                type="email"
                name="email"
                label="Email Address*"
                variant="outlined"
                value={values.email}
                onChange={handleChange}
                error={touched.email && Boolean(touched.email)}
                helperText={touched.email && errors.email}
              />

              <StyledTextField
                id="course"
                type="text"
                name="course"
                label="Select Course*"
                variant="outlined"
                value={values.course}
                onChange={handleChange}
                error={touched.course && Boolean(touched.course)}
                helperText={touched.course && errors.course}
              />
              <StyledTextField
                id="phone"
                type="text"
                name="phone"
                variant="outlined"
                label="Phone Number*"
                value={values.phone}
                onChange={handleChange}
                error={touched.phone && Boolean(touched.phone)}
                helperText={touched.phone && errors.phone}
              />

              <StyledTextField
                id="message"
                variant="outlined"
                name="message"
                label="Write Your Message"
                value={values.message}
                onChange={handleChange}
                multiline
                rows={4}
                error={touched.message && Boolean(touched.message)}
                helperText={touched.message && errors.message}
                sx={{
                  flex: "0 0 calc(100% - 14px)",
                  maxWidth: "calc(100% - 14px)",
                  "& > div": {
                    background: "#fff",
                  },
                }}
              />
              <Button
                type="submit"
                variant="contained"
                endIcon={<East />}
                style={{
                  flex: "0 0 calc(100% - 14px)",
                  maxWidth: "calc(100% - 14px)",
                  margin: "0 auto 20px",
                  padding: "17px 31px",
                }}
                disabled={!!isLoading}
              >
                {isLoading ? "SUBMITTING..." : "SUBMIT MESSAGE"}
              </Button>
            </Stack>
          </form>
        </Wrapper>
      </Main>
    </>
  );
}
